import React, { useState, useRef, useEffect } from 'react'
import { Form, Row, Col, notification, Modal } from 'antd'
import { OverScorePanel, ScoreNumPad, ScoreUtils } from './'
import './InningTableItem.css'
import { AuthService } from '../../services'

export const InningTabItem = ({ inning, updateInningScoreInfo, props }) => {

    const { confirm } = Modal

    let period = props.period
    let checkScoreInput = props.checkScoreInput
    let currentBattingTeam = props.currentBattingTeam
    let overConfirmationStatusDict = props.overConfirmationStatusDict
    let setOverConfirmationStatusDict = props.setOverConfirmationStatusDict
    let inningOverScoreDict = props.inningOverScoreDict
    let setInningOverScoreDict = props.setInningOverScoreDict
    let disableScoreInputDict = props.disableScoreInputDict
    let form = props.form
    let disableInningContent = checkScoreInput ? currentBattingTeam !== period.final ? disableScoreInputDict[inning] : true : !checkScoreInput
    let event = props.event
    let mapObumTeamName = props.mapObumTeamName
    let mapObumInning = props.mapObumInning
    let updateOverScoreDict = props.updateOverScoreDict
    let sortDeliveryDictionary = props.sortDeliveryDictionary
    let overScoreDict = inningOverScoreDict[inning] ?? { 1: { '0.1.0': '' } }
    let constructLastSixDeliveriesData = props.constructLastSixDeliveriesData
    let lastAddedOverDict = props.lastAddedOverDict
    let lastAddedDeliveryDict = props.lastAddedDeliveryDict
    let focusDeliveryDict = props.focusDeliveryDict
    let onFocus = props.onFocus
    let onFocusInputDict = props.onFocusInputDict
    let setIsLoading = props.setIsLoading
    let updateLastAddedDeliveryDict = props.updateLastAddedDeliveryDict
    let updateFocusDeliveryDict = props.updateFocusDeliveryDict
    let getScoreInfo = props.getScoreInfo
    let overConfirmationStatusList = overConfirmationStatusDict !== null && inning in overConfirmationStatusDict ? overConfirmationStatusDict[inning] : { 1: false }
    let setScoreInfoChanged = props.setScoreInfoChanged
    let getLastItemsInDictionary = props.getLastItemsInDictionary
    let eventInfo = props.eventInfo
    let getLastDeliveryWithScore = props.getLatestDeliveryWithScore
    let ballRunStatusDict = props.ballRunStatusDict
    let ballRunStatus = ballRunStatusDict !== null && inning in ballRunStatusDict ? ballRunStatusDict[inning] : { inning: 0 }
    let submitBallRunStatusChange = props.submitBallRunStatusChange
    let submitScoreInfoChange = props.submitScoreInfoChange
    let submitResultInfoChange = props.submitResultInfoChange
    let updateOverConfirmStatus = props.updateOverConfirmStatus
    let submitScoreInfoRemoved = props.submitScoreInfoRemoved

    const scoreInputRef = useRef([])
    const [currentOver, setCurrentOver] = useState(lastAddedOverDict[inning] ?? 1)
    const [currentDelivery, setCurrentDelivery] = useState(lastAddedDeliveryDict[inning] ?? '0.1.0')
    const [currentExpandOver, setCurrentExpandOver] = useState([1])

    function increaseExtra(currentDelivery) {
        const [completedOver, completedDelivery, extraNo] = currentDelivery.split('.').map(Number)

        const newExtraNo = extraNo + 1

        return `${completedOver}.${completedDelivery}.${newExtraNo}`
    }

    function increaseDelivery(currentDelivery) {
        const [completedOver, completedDelivery, ] = currentDelivery.split('.').map(Number)

        var newCompletedOver = completedOver
        var newCompletedDelivery = completedDelivery
        if (completedDelivery === 5) {
            newCompletedDelivery = 0
            newCompletedOver = newCompletedOver + 1
        }
        else {
            newCompletedDelivery = newCompletedDelivery + 1
        }

        return `${newCompletedOver}.${newCompletedDelivery}.0`
    }

    function getNewDelivery(newOver) {
        return `${newOver - 1}.1.0`
    }

    function isLastDelivery(delivery, score) {
        const [, completedDelivery,] = delivery.split('.').map(Number)

        return completedDelivery === 0 && (!ScoreUtils.isExtraScore(score) || ScoreUtils.hasExceedExtraLimit(delivery))
    }

    function increaseOver(lastAddedOver) {
        var newOver = lastAddedOver
        var floatOver = parseFloat(lastAddedOver)
        newOver = (floatOver + 1).toFixed(0)
        return newOver
    }

    const isNormalScoreExistedWithinDelivery = () => {
        let count = 0
        for (const key in overScoreDict[currentOver]) {
            if (ScoreUtils.getDisplayDelivery(lastAddedDeliveryDict[inning]) === ScoreUtils.getDisplayDelivery(key) && !ScoreUtils.isExtraScore(overScoreDict[currentOver][key])) {
                count++
            }
        }
        return count
    }

    const canInputNormalScore = () => {
        var canInput = true

        if (currentOver in overScoreDict) {
            var overScores = overScoreDict[currentOver]
            if (lastAddedDeliveryDict[inning] in overScores) {
                var deliveryScore = overScores[lastAddedDeliveryDict[inning]]
                canInput = deliveryScore === null || deliveryScore === '' || !ScoreUtils.isExtraScore(deliveryScore)
            }
        }

        return canInput
    }

    const onBallRunStatusClicked = (e) => {
        submitBallRunStatusChange(inning, e)
    }

    const checkTotalWicketOut = () => {
        var totalWicketOut = ScoreUtils.getInningTotalWicketOut(overScoreDict)
        if (totalWicketOut >= 10) {
            Modal.warning({
                title: 'Current Inning reaches 10 wickets out.',
                onOk() { },
            });
        }
    }

    const checkPreviousUnconfirmStatus = (e) => {
        let lastAddedOver = lastAddedOverDict[inning]
        let hasAnyUnconfirmResult = ScoreUtils.hasAnyUnconfirmOver(overConfirmationStatusList, currentOver)
        if (hasAnyUnconfirmResult && Number(lastAddedOver) === currentOver) {
            Modal.warning({
                title: 'There is an unconfirmed previous over, please confirm to proceed.',
                onOk() { },
            });
        }
        else {
            onScoreClicked(e)
        }
    }

    const onScoreClicked = async (e) => {
        setIsLoading(true)

        if (!ScoreUtils.isExtraScore(e) && !canInputNormalScore() && isNormalScoreExistedWithinDelivery() > 0) {
            notification['warning']({
                message: '',
                description: `Delivery only allow to input extra score or remove`,
                duration: 10
            })
        }
        else {
            let lastAddedOver = lastAddedOverDict[inning]
            let lastAddedDelivery = lastAddedDeliveryDict[inning]

            var isSuccess = await submitScoreInfoChange(e, inning, lastAddedOver, lastAddedDelivery)
            if(isSuccess){
                updateOverScoreDict(inning, lastAddedOver, lastAddedDelivery, e)
                const dict = { ...overConfirmationStatusList }
                dict[lastAddedOver.toString()] = false
                setOverConfirmationStatusDict({ ...overConfirmationStatusDict, [inning]: dict })

                if (lastAddedOver === currentOver.toString() && lastAddedDelivery === currentDelivery.toString()) {
                    if (!isLastDelivery(lastAddedDelivery) || (isLastDelivery(lastAddedDelivery) && ScoreUtils.isExtraScore(e) && !ScoreUtils.hasExceedExtraLimit(lastAddedDelivery))) {
                        var isExtra = ScoreUtils.isExtraScore(e) && !ScoreUtils.hasExceedExtraLimit(lastAddedDelivery)
                        var newDelivery = isExtra ? increaseExtra(lastAddedDelivery) : increaseDelivery(lastAddedDelivery)

                        var shouldAddNewDelivery = !hasNextDeliveryValue() || isExtra
                        if (shouldAddNewDelivery) {
                            updateOverScoreDict(inning, lastAddedOver, newDelivery, '')
                            setCurrentDelivery(newDelivery)
                        }

                        isExtra ? updateFocusDeliveryDict(inning, newDelivery) : focusLatestDelivery()
                    }
                }
                else {
                    if (ScoreUtils.isExtraScore(e) && !ScoreUtils.hasExceedExtraLimit(lastAddedDelivery)) {
                        var isNextDeliveryHasValue = hasNextDeliveryValue()
                        if (!isNextDeliveryHasValue) {
                            onRemoveExtraScore(lastAddedOver, currentDelivery)
                            updateLastAddedDeliveryDict(inning, lastAddedDelivery)
                        }

                        var nextDelivery = increaseExtra(lastAddedDelivery)
                        if (!overScoreDict[lastAddedOver].hasOwnProperty(nextDelivery)) {
                            updateOverScoreDict(inning, lastAddedOver, nextDelivery, '')
                            setCurrentDelivery(nextDelivery)
                        }

                        var deliveryToFocus = findFirstEmptyKey(overScoreDict[lastAddedOver])
                        if (deliveryToFocus !== null) {
                            updateFocusDeliveryDict(inning, deliveryToFocus)
                        }
                    }
                    else {
                        focusLatestDelivery()
                        var latestDelivery = getLatestDelivery()
                        setCurrentDelivery(latestDelivery)
                    }
                }

                checkTotalWicketOut()
                // setScoreInfoChanged(true)
            }
        }

        setIsLoading(false)
    }

    function getOverUnconfirmKeys() {
        const dict = { ...overConfirmationStatusList }
        return Object.entries(dict).filter(([key, val]) => {
            return !val
        }).map(([key, val]) => {
            return Number(key)
        })
    }

    function hasNextDeliveryValue() {
        const keys = sortDeliveryDictionary(overScoreDict[currentOver])
        const index = keys.indexOf(lastAddedDeliveryDict[inning])

        return index < keys.length - 1
    }

    function getLatestDelivery() {
        const keys = sortDeliveryDictionary(overScoreDict[currentOver])
        return keys[keys.length - 1]
    }

    //const filteredResult = Object.entries(overScoreDict).reduce((result, [key, value]) => {
    //    Object.entries(value).forEach(([subKey, subValue]) => {
    //      if (subValue !== undefined && subValue !== '') {
    //        result[subKey] = subValue
    //      }
    //    });
    //    return result
    //  }, {})

    //function getLatestDeliveryWithScore() {
    //    const keys = sortDeliveryDictionary(filteredResult)
    //    return keys[keys.length - 1]
    //}

    function getLatestDeliveryWithScore() {
        const filteredResult = Object.entries(overScoreDict).reduce((result, [key, value]) => {
            Object.entries(value).forEach(([subKey, subValue]) => {
                if (subValue !== undefined && subValue !== '') {
                    result[subKey] = subValue
                }
            });
            return result
        }, {})

        const keys = sortDeliveryDictionary(filteredResult)
        return keys[keys.length - 1]
    }

    function focusLatestDelivery() {
        updateFocusDeliveryDict(inning, getLatestDelivery())
    }

    const updateOverConfirmationStatusDict = (newDict) => {
        if (overConfirmationStatusDict !== null) {
            setOverConfirmationStatusDict({ ...overConfirmationStatusDict, [inning]: newDict });
        } else {
            setOverConfirmationStatusDict({ [inning]: newDict })
        }
    }

    const onChangeCollapse = (key) => {
        setCurrentExpandOver(key)
    }

    const constructManualCricketResultInfo = (over) => {
        var deliveryScoreList = overScoreDict[over]
        var lastAddedDelivery = lastAddedDeliveryDict[inning]
        var filteredDeliveryScores = Object.entries(deliveryScoreList).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {})
        var inningDetailList = Object.entries(filteredDeliveryScores)
            .map(([key, value], index) => {
                var manualCricketInningDetail = {
                    inningNo: mapObumInning(inning),
                    overNo: over,
                    deliveryNo: ++index,
                    deliveryValue: value
                }
                return manualCricketInningDetail
            })

        return {
            eventId: event.eventId,
            inningNo: mapObumInning(inning),
            battingTeam: mapObumTeamName(inning),
            totalRuns: ScoreUtils.getInningTotalRuns(overScoreDict),
            totalWickets: ScoreUtils.getInningTotalWicketOut(overScoreDict),
            completedOver: ScoreUtils.getOverNumber(lastAddedDelivery),
            completedDelivery: ScoreUtils.getDeliveryNumber(lastAddedDelivery),
            inningDetailList: inningDetailList,
            matchStartTime: event.matchStartTime,
            sessionId: AuthService.currentUserValue.sessionId,
            updatedBy: AuthService.currentUserValue.name,
            inning: inning,
            overToConfirm: Number(over),
            uiRequestId: Date.now()
        }
    }

    const onOverConfirmed = async (over) => {
        var latestDeliveryWithScore = getLatestDeliveryWithScore()
        var overNo = ScoreUtils.getOverNumber(latestDeliveryWithScore)
        var deliveryNo = ScoreUtils.getDeliveryNumber(latestDeliveryWithScore)
        if (deliveryNo !== 0 && overNo + 1 === Number(over) && deliveryNo !== 6) {
            showConfirmation(over)
        }
        else {
            await confirmOverAction(over, true)
        }
    }

    const showConfirmation = (over) => {
        confirm({
            title: `Over ${over} is incomplete. Are you confirm to end the Over?`,
            cancelText: 'No',
            okText: 'Yes',
            onOk() {
                confirmOverAction(over, false)
            },
            onCancel() {
                
            },
        })
    }

    const confirmOverAction = async (over, isEndOfOver) =>
    {
        setIsLoading(true)
        var isSuccess = await submitResultInfoChange(over, inning, overScoreDict)
        if (isSuccess) {
            const dict = { ...overConfirmationStatusList }
            dict[over] = true

            updateOverConfirmationStatusDict(dict)

            //Add new over
            var shouldAddNewOver = isEndOfOver
            if(shouldAddNewOver){
                if (eventInfo.competitionCategory === 'T20' && over >= 20){
                    shouldAddNewOver = false
                }
                else if (eventInfo.competitionCategory === 'ODI' && over >= 50){
                    shouldAddNewOver = false
                }
            }
            
            if(shouldAddNewOver){
                var newOver = increaseOver(over)
                
                if (!overScoreDict.hasOwnProperty(newOver)) {
                    var newDelivery = getNewDelivery(newOver)

                    var isOverStatusUpdateSuccess = await updateOverConfirmStatus(inning, newOver, newDelivery)
                    if(isOverStatusUpdateSuccess){
                        dict[newOver] = false
                        updateOverConfirmationStatusDict(dict)
                        setCurrentOver(newOver)
                        setCurrentDelivery(newDelivery)
                        updateOverScoreDict(inning, newOver, newDelivery, '')
                        setCurrentExpandOver([newOver])
                        updateFocusDeliveryDict(inning, newDelivery)
                    }
                }
            }
            notification['success']({
                message: '',
                description: `${inning} Over ${over} confirmed`,
            })
        }
        setIsLoading(false)
    }

    const onRemoveScore = (over, deliveryToDelete, score) => {
        var isExtraScore = ScoreUtils.isExtraScore(score)
        if (isExtraScore) {
            onRemoveExtraScore(over, deliveryToDelete)
        }
        else {
            onRemoveNormalScore(over, deliveryToDelete)
        }
        //setScoreInfoChanged(true)
    }

    const onRemoveExtraScore = (over, deliveryToDelete) => {
        const newDict = {}
        const newOverScoreDict = { ...overScoreDict }
        const innerObject = newOverScoreDict[over]
        const deliveryToDeleteParts = deliveryToDelete.split('.').map(Number)

        for (const key in innerObject) {
            const keyParts = key.split('.').map(Number)
            if(deliveryToDeleteParts[0] === keyParts[0] && deliveryToDeleteParts[1] === keyParts[1]){
                let newKey = key
                const versionParts = newKey.split('.').map(Number)
                if (versionParts[2] > deliveryToDeleteParts[2]) {
                    versionParts[2]--
                }

                newKey = versionParts.join('.')
                newDict[newKey] = innerObject[key]
            }
            else{
                newDict[key] = innerObject[key]
            }
        }

        const deepCopyScoreDict = JSON.parse(JSON.stringify(newOverScoreDict))
        deepCopyScoreDict[over] = newDict
        var isSucces = submitScoreInfoRemoved(deepCopyScoreDict, over, false)
        if(isSucces){
            newOverScoreDict[over] = newDict
            setInningOverScoreDict({ ...inningOverScoreDict, [inning]: newOverScoreDict })

            const dict = { ...overConfirmationStatusList }
            dict[over.toString()] = false
            setOverConfirmationStatusDict({ ...overConfirmationStatusDict, [inning]: dict })

            var deliveryToFocus = findFirstEmptyKey(newOverScoreDict[over])
            if (deliveryToFocus !== null) {
                updateFocusDeliveryDict(inning, deliveryToFocus)
            }
        }
    }

    const onRemoveNormalScore = (over, deliveryToDelete) => {
        const newDict = {}
        const newOverScoreDict = { ...overScoreDict }
        const innerObject = newOverScoreDict[over]
        const deliveryToDeleteParts = deliveryToDelete.split('.').map(Number)
        if(deliveryToDeleteParts[1] === 0){
            deliveryToDeleteParts[0]--
            deliveryToDeleteParts[1] = 6
        }

        for (const key in innerObject) {
            const keyParts = key.split('.').map(Number)
            if(keyParts[1] === 0){
                keyParts[0]--
                keyParts[1] = 6
            }

            if(deliveryToDeleteParts[0] === keyParts[0]){
                if(keyParts[1] >= deliveryToDeleteParts[1]){
                    if (deliveryToDeleteParts[1] === 6 && keyParts[2] === 0 && newDict[key] === undefined){
                        newDict[key] = ''
                    }
                    else if (deliveryToDeleteParts[1] === keyParts[1]) {
                    }
                    else{
                        let newKey = key
                        const versionParts = newKey.split('.').map(Number)
                        if(versionParts[1] === 0){
                            versionParts[0]--
                            versionParts[1] = 6
                        }
                        if (versionParts[1] > deliveryToDeleteParts[1]) {
                            versionParts[1]--
                        }
        
                        newKey = versionParts.join('.')
                        newDict[newKey] = innerObject[key]
                    }
                }
                else{
                    newDict[key] = innerObject[key]
                }
            }
            else{
                newDict[key] = innerObject[key]
            }
        }

        const deepCopyScoreDict = JSON.parse(JSON.stringify(newOverScoreDict))
        deepCopyScoreDict[over] = newDict
        var isSucces = submitScoreInfoRemoved(deepCopyScoreDict, over, true)
        if(isSucces){
            newOverScoreDict[over] = newDict
            setInningOverScoreDict({ ...inningOverScoreDict, [inning]: newOverScoreDict })

            const dict = { ...overConfirmationStatusList }
            dict[over.toString()] = false
            setOverConfirmationStatusDict({ ...overConfirmationStatusDict, [inning]: dict })

            var deliveryToFocus = findFirstEmptyKey(newOverScoreDict[over])
            if (deliveryToFocus !== null) {
                updateFocusDeliveryDict(inning, deliveryToFocus)
            }
        }
    }

    function findFirstEmptyKey(obj) {
        const sortedKeys = sortDeliveryDictionary(obj)

        for (const key of sortedKeys) {
            if (obj[key] === '') {
                return key
            }
        }

        return null
    }

    function sortArray(array){
        return array.sort((a, b) => {
            // Splitting the version strings into arrays of numbers
            const versionA = a.split('.').map(Number)
            const versionB = b.split('.').map(Number)
          
            // Comparing each segment of the version strings
            for (let i = 0; i < versionA.length; i++) {
              if (versionA[i] !== versionB[i]) {
                return versionA[i] - versionB[i]
              }
            }
          
            return 0 // If versions are identical
          })
    }

    useEffect(() => {
        if (inningOverScoreDict[inning] === undefined) {
            updateOverScoreDict(inning, 1, '0.1.0', '')
            onFocus(inning, 1, '0.1.0', '0.1.0')
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (scoreInputRef.current && focusDeliveryDict[inning]) {
            const inputRefToFocus = scoreInputRef.current[focusDeliveryDict[inning]]
            if (inputRefToFocus) {
                inputRefToFocus.focus()
            }
        }
    }, [focusDeliveryDict]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (inningOverScoreDict[inning] !== undefined) {
            var lastOverDelivery = inningOverScoreDict[Object.keys(inningOverScoreDict)[Object.keys(inningOverScoreDict).length - 1]]
            if (lastOverDelivery !== undefined) {
                var lastOver = Object.keys(lastOverDelivery).pop()
                var lastDelivery = sortArray(Object.keys(lastOverDelivery[lastOver])).pop()
                setCurrentOver(parseInt(lastOver))
                setCurrentDelivery(lastDelivery)
            }
        }
        else {
            setCurrentOver(1)
            setCurrentDelivery('0.1.0')
            setCurrentExpandOver([1])
        }

        var last6Deliveries = getLastItemsInDictionary(6, overScoreDict)
        var obj = constructLastSixDeliveriesData(last6Deliveries)

        updateInningScoreInfo(inning, getScoreInfo(overScoreDict, getLatestDeliveryWithScore()), obj)
    }, [inningOverScoreDict]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        var overUnconfirmedKeys = getOverUnconfirmKeys()
        if(JSON.stringify(overUnconfirmedKeys) !== JSON.stringify(currentExpandOver)){
            onChangeCollapse(overUnconfirmedKeys)
        }
        
    }, [overConfirmationStatusList])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form form={form} name="inningTabItem" disabled={disableInningContent}>
            <Row>
                <Col span={11}>
                    <div style={{ overflow: 'auto', minHeight: '80vh', height: '75vh' }}>
                        <OverScorePanel
                            inning={inning}
                            overScoreDict={overScoreDict}
                            currentExpandOver={currentExpandOver}
                            onChangeCollapse={onChangeCollapse}
                            scoreInputRef={scoreInputRef}
                            onOverConfirmed={onOverConfirmed}
                            overConfirmationStatusList={overConfirmationStatusList}
                            onRemoveScore={onRemoveScore}
                            disableConfirmBtn={disableInningContent}
                            onFocus={onFocus}
                            onFocusInputDict={onFocusInputDict}
                            getLastDeliveryWithScore={getLastDeliveryWithScore}></OverScorePanel>
                    </div>
                </Col>
                <Col span={11} offset={2}>
                    <ScoreNumPad onScoreClicked={checkPreviousUnconfirmStatus} onBallRunStatusClicked={onBallRunStatusClicked} ballRunStatus={ballRunStatus}></ScoreNumPad>
                </Col>
            </Row>
        </Form>
    )
}

