import axios from 'axios'
import { AuthService } from '../services'

const logError = async (callerName, infoKeyValue, message) => {

    var sessionId = AuthService.currentUserValue?.sessionId
    if(sessionId !== null && sessionId !== undefined){
        infoKeyValue.sessionId = AuthService.currentUserValue?.sessionId
    }
    
    var logInfo = {
        callerName: callerName,
        username: AuthService.currentUserValue?.name,
        infoKeyValue: infoKeyValue,
        message: message
    }
    return axios.post('Logging/logError', logInfo).catch(console.log)
}

const logInfo = async (callerName, infoKeyValue, message) => {

    var sessionId = AuthService.currentUserValue?.sessionId
    if(sessionId !== null && sessionId !== undefined){
        infoKeyValue.sessionId = AuthService.currentUserValue?.sessionId
    }
    
    var logInfo = {
        callerName: callerName,
        username: AuthService.currentUserValue?.name,
        infoKeyValue: infoKeyValue,
        message: message
    }
    return axios.post('Logging/logInfo', logInfo).catch(console.log)
}

export const LoggingService = {
    logError,
    logInfo
}