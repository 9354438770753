import axios from 'axios'
import { LoggingService } from '../services'

const updateManualCricketMatchInfo = async (matchInfo) => {
    const response = await axios.put('ManualCricket/UpdateManualCricketMatchInfo', matchInfo)
    .catch((error) => 
    {
        var infoKeyValue = {
            eventId: matchInfo?.eventId
        }
        
        LoggingService.logError('updateManualCricketMatchInfo', infoKeyValue, JSON.stringify(error.toJSON())) 
    })
    return response?.data?.result
}

const updateManualCricketScoreInfo = async (scoreInfo) => {
    const response = await axios.put('ManualCricket/UpdateManualCricketScoreInfo', scoreInfo)
    .catch((error) =>
    {
        var infoKeyValue = {
            eventId: scoreInfo?.eventId
        }

        LoggingService.logError('updateManualCricketScoreInfo', infoKeyValue, JSON.stringify(error.toJSON()))
    })
    return response?.data?.result
}

const updateManualCricketResultInfo = async (resultInfo) => {
    const response = await axios.put('ManualCricket/UpdateManualCricketResultInfo', resultInfo)
    .catch((error) =>
    {
       var infoKeyValue = {
           eventId: resultInfo?.eventId
       }

       LoggingService.logError('updateManualCricketScoreInfo', infoKeyValue, JSON.stringify(error.toJSON()))
    })
    return response?.data?.result
}

const resumeManualCricketInfo = async (eventId, sessionId, requestedBy) => {
    const response = await axios.get(`ManualCricket/ResumeManualCricketInfo?eventId=${eventId}&sessionId=${sessionId}&requestedBy=${requestedBy}`)
    .catch((error) =>
    {
       var infoKeyValue = {
           eventId: eventId
       }

        LoggingService.logError('resumeManualCricketInfo', infoKeyValue, JSON.stringify(error.toJSON()))
    })
    return response?.data?.result
}

export const ManualTradingService = {
    updateManualCricketMatchInfo,
    updateManualCricketScoreInfo,
    updateManualCricketResultInfo,
    resumeManualCricketInfo
}
