import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Form, Row, Col, Result, Spin, notification } from 'antd'
import { MatchInfoInput, ScoreInfoInput, ScoreUtils } from '../../components/ManualTrading'
import { ManualTradingService, AuthService, LoggingService } from '../../services'

export const ManualTradingInputPage = () => {

    const location = useLocation()
    const eventInfo = location.state || {}

    const period = {
        home: '1st Inning Home',
        away: '1st Inning Away',
        ht: 'HT',
        ft: 'FT',
        so1: 'SO1',
        so1Home: 'SO1 Home',
        so1Away: 'SO1 Away',
        so2: 'SO2',
        so2Home: 'SO2 Home',
        so2Away: 'SO2 Away',
        so3: 'SO3',
        so3Home: 'SO3 Home',
        so3Away: 'SO3 Away',
        final: 'Final',
        soHome: 'Home',
        soAway: 'Away'
    }

    const obumInningEnum = {
        inning1: 1,
        inning2: 2,
        superOver: 3,
        coinTossResult: 4
    }

    const obumTeam = {
        home: 'Home',
        away: 'Away',
    }

    function getWinTheTossTeam() {
        if (event.homeTeamName === whoWinTossValue)
            return obumTeam.home
        else if (event.awayTeamName === whoWinTossValue)
            return obumTeam.away
        else
            return undefined
    }

    function mapObumTeamName(matchPeriod) {
        switch (matchPeriod) {
            case period.home:
            case period.so1Home:
            case period.so2Home:
            case period.so3Home:
                return obumTeam.home
            case period.away:
            case period.so1Away:
            case period.so2Away:
            case period.so3Away:
                return obumTeam.away
            default:
                return undefined
        }
    }

    function mapObumInning(matchPeriod) {
        switch (matchPeriod) {
            case period.home:
            case period.away:
                return obumInningEnum.inning1
            case period.so1Home:
            case period.so2Home:
            case period.so3Home:
            case period.so1Away:
            case period.so2Away:
            case period.so3Away:
                return obumInningEnum.superOver
            default:
                return undefined
        }
    }

    const [form] = Form.useForm()
    const [checkScoreInput, setCheckScoreInput] = useState(false)
    const [currentBattingTeam, setCurrentBattingTeam] = useState("")
    const [matchPeriods, setMatchPeriods] = useState([])
    const [inningScoreInfo, setInningScoreInfo] = useState({})
    const [currentActiveInningTab, setCurrentActiveInningTab] = useState('')
    const [overConfirmationStatusDict, setOverConfirmationStatusDict] = useState(null)
    const [inningOverScoreDict, setInningOverScoreDict] = useState({}) // [period.home]: {}, [period.away]: {}, [period.so1Home]: {}, [period.so1Away]: {}, [period.so2Home]: {}, [period.so2Away]: {}, [period.so3Home]: {}, [period.so3Away]: {}
    const [disableScoreInputDict, setDisableScoreInputDict] = useState({ [period.home]: false, [period.away]: false, [period.so1Home]: false, [period.so1Away]: false, [period.so2Home]: false, [period.so2Away]: false, [period.so3Home]: false, [period.so3Away]: false })
    const [event, setEvent] = useState({})
    const [whoWinTossValue, setWhoWinTossValue] = useState(undefined)
    const [battingTeamToggleState, setBattingTeamToggleState] = useState([true, true, true, true])
    const [currentMatchPeriod, setCurrentMatchPeriod] = useState("")
    const [superOverToggleState, setSuperOverToggleState] = useState([true, true, true])
    const [fullTime, setFullTime] = useState(false)
    const [isResuming, setIsResuming] = useState(false)
    const [finalButtonType, setFinalButtonType] = useState('default')
    const [superOverTeamToggleState, setSuperOverTeamToggleState] = useState([true, true])
    const [isLoading, setIsLoading] = useState(false)
    const [last6Deliveries, setLast6Deliveries] = useState([
        {
            key: 1,
            d1: '',
            d2: '',
            d3: '',
            d4: '',
            d5: '',
            d6: '',
        }
    ])
    //const [lastAddedOver, setLastAddedOver] = useState({})
    //const [lastAddedDelivery, setLastAddedDelivery] = useState({})
    const [lastAddedOverDict, setLastAddedOverDict] = useState({})
    const [lastAddedDeliveryDict, setLastAddedDeliveryDict] = useState({})
    const [focusDeliveryDict, setFocusDeliveryDict] = useState({})
    const [onFocusInputDict, setOnFocusInputDict] = useState({})
    const [disableSOHomeAway, setDisableSOHomeAway] = useState(true)
    const [superOverVal, setSuperOverVal] = useState(null)
    const [scoreChange, setScoreChange] = useState(false)
    const [disableWhoWinToss, setDisableWhoWinToss] = useState(false)
    const [disableScoreInput, setDisableScoreInput] = useState(false)
    const [ballRunStatusDict, setBallRunStatusDict] = useState({})
    const [scoreInfoChanged, setScoreInfoChanged] = useState(false)

    const onEnableScoreInputChange = (isChecked) => {
        setCheckScoreInput(isChecked)
    }

    const onWhoWinTossConfirm = (teamName) => {
        setWhoWinTossValue(teamName)
        setBattingTeamToggleState([false, false, true, true])
    }

    const checkMatchPeriodExists = (matchPeriod) => matchPeriods.some((item) => item.matchPeriod === matchPeriod)

    const onBattingTeamConfirm = (matchPeriod, teamName) => {
        setCurrentMatchPeriod(matchPeriod)
        setCurrentBattingTeam(teamName)

        handleInningChange(matchPeriod)

        if (matchPeriods?.length === 1 && matchPeriod !== period.ht) { // 1st Inn
            setMatchPeriods(matchPeriods.splice(0, 1));
        }
        if (matchPeriods?.length === 3 && matchPeriod === period.ft) { // FT
            setFullTime(true)
            form.setFieldValue('superOver', undefined)
            setSuperOverToggleState([false, true, true])
            setSuperOverTeamToggleState([true, true])
        }
        
        if(!checkMatchPeriodExists(matchPeriod)){
            setMatchPeriods([...matchPeriods, { matchPeriod: matchPeriod }])
        }
    }

    const controlBattingTeamButtons = (matchPeriod) => {
        if(matchPeriod === null || matchPeriod === ''){
            setBattingTeamToggleState([true, true, true, true])
        }
        else if (matchPeriods?.length === 1 && matchPeriod !== period.ht
            && inningOverScoreDict !== null && Object.keys(inningOverScoreDict).length >= 1
            && inningOverScoreDict[matchPeriod] !== undefined
            && inningOverScoreDict[matchPeriod]['1'] !== undefined && inningOverScoreDict[matchPeriod]['1']['0.1.0'] !== '') { // HT
            var firstInn = matchPeriods[0].matchPeriod
            setDisableWhoWinToss(true)
            setBattingTeamToggleState([firstInn !== period.home, firstInn !== period.away, false, true])
        }
        else if (matchPeriod === period.ht) { // HT
            var firstInning = matchPeriods[0].matchPeriod
            setDisableWhoWinToss(true)
            setBattingTeamToggleState([firstInning === period.home, firstInning === period.away, false, true])
            disableScoreInputControls(firstInning)
        }
        else if (matchPeriods?.length === 3 && matchPeriod !== period.ht) { // 2nd Inn
            var secondInn = matchPeriods[2].matchPeriod
            setDisableWhoWinToss(true)
            setBattingTeamToggleState([secondInn !== period.home, secondInn !== period.away, true, false])
        }
        else if (matchPeriods?.length === 4 && matchPeriod === period.ft) { // FT
            var lastInn = matchPeriods[2].matchPeriod
            setDisableWhoWinToss(true)
            setBattingTeamToggleState([true, true, true, false])
            setSuperOverToggleState([false, true, true])
            setSuperOverTeamToggleState([true, true])
            disableScoreInputControls(lastInn)
            setFullTime(true)
        }
        else if (matchPeriods?.length === 0) { // default
            setBattingTeamToggleState([false, false, true, true])
        }
    }

    const onFinalConfirm = () => {
        setCurrentMatchPeriod(period.final)
        setSuperOverToggleState([true, true, true])
        setSuperOverTeamToggleState([true, true])
        setBattingTeamToggleState([true, true, true, true])
        setFinalButtonType('primary')
        var lastMatchPeriod = matchPeriods[matchPeriods.length - 1]?.matchPeriod
        disableScoreInputControls(lastMatchPeriod)
    }

    const updateOverScoreDict = (inning, over, delivery, value) => {
        var overScoreDict = inningOverScoreDict[inning]
        const updatedDictionary = { ...overScoreDict }

        if (over in updatedDictionary) {
            updatedDictionary[over][delivery] = value
        }
        else {
            updatedDictionary[over] = { [delivery]: value }
        }

        setInningOverScoreDict({ ...inningOverScoreDict, [inning]: updatedDictionary })
    }

    function updateInningScoreInfo(inning, score, lastSixDeliveries) {
        setInningScoreInfo({ ...inningScoreInfo, [inning]: score })
        setLast6Deliveries(lastSixDeliveries)
    }

    const disableScoreInputControls = (inn) => {
        let dict = { ...disableScoreInputDict }
        dict[inn] = true
        setDisableScoreInputDict(dict)
    }

    const handleInningChange = (inning) => {
        if(inning === null || inning === ''){
            return
        }

        if(inning !== 'HT' && inning !== 'FT' && inning !== 'Final'){
            const updatedScoreInfo = { ...inningScoreInfo }
            if(Object.keys(updatedScoreInfo).length === 1){
                var firstItemVal = Object.entries(inningScoreInfo)[0][1]
                if(firstItemVal === '0/0 (0.0)'){
                    var firstItemKey = Object.entries(inningScoreInfo)[0][0]
                    delete updatedScoreInfo[firstItemKey]
                    setInningScoreInfo(updatedScoreInfo)

                    var updateOverScoreDict = {...inningOverScoreDict}
                    delete updateOverScoreDict[firstItemKey]
                    setInningOverScoreDict(updateOverScoreDict)
                }
            }
            if(!(inning in updatedScoreInfo)){
                updatedScoreInfo[inning] = '0/0 (0.0)'
                setInningScoreInfo(updatedScoreInfo)
                setCurrentActiveInningTab(inning)
            }
        }
    }

    function getBattingTeamName(inning, homeTeamName, awayTeamName) {
        var battingTeam = inning
        if (inning !== 'HT' && inning !== 'FT' && inning !== 'Final') {
            battingTeam = inning.includes('Home') ? homeTeamName : awayTeamName
        }
        return battingTeam
    }

    const getScoreInfo = (overScoreDict, lastDelivery) => {
        var totalRuns = ScoreUtils.getInningTotalRuns(overScoreDict)
        var totalWicketOut = ScoreUtils.getInningTotalWicketOut(overScoreDict)
        var completedDelivery = lastDelivery !== null ? `${ScoreUtils.getDisplayDelivery(lastDelivery)}` : '0.0'

        return `${totalRuns}/${totalWicketOut} (${completedDelivery})`
    }
    
    function constructLastSixDeliveriesData(last6Deliveries) {
        return [
            {
                key: 1,
                d1: last6Deliveries.length >= 6 ? last6Deliveries[last6Deliveries.length - 6] : '',
                d2: last6Deliveries.length >= 5 ? last6Deliveries[last6Deliveries.length - 5] : '',
                d3: last6Deliveries.length >= 4 ? last6Deliveries[last6Deliveries.length - 4] : '',
                d4: last6Deliveries.length >= 3 ? last6Deliveries[last6Deliveries.length - 3] : '',
                d5: last6Deliveries.length >= 2 ? last6Deliveries[last6Deliveries.length - 2] : '',
                d6: last6Deliveries.length >= 1 ? last6Deliveries[last6Deliveries.length - 1] : '',
            }
        ]
    }

    function formatGmtMinus4DateTime(dateTimeISO) {
        const dateTime = new Date(dateTimeISO)
        var utcDateTime = new Date(dateTime.getTime() + (dateTime.getTimezoneOffset() * 60 * 1000))
        var gmtMinus4DateTime = new Date(utcDateTime.getTime() - (240 * 60 * 1000))
        if (gmtMinus4DateTime !== undefined) {
            return gmtMinus4DateTime.getFullYear() + "-" +
                ("0" + (gmtMinus4DateTime.getMonth() + 1)).slice(-2) + "-" +
                ("0" + gmtMinus4DateTime.getDate()).slice(-2) + " " +
                ("0" + gmtMinus4DateTime.getHours()).slice(-2) + ":" +
                ("0" + gmtMinus4DateTime.getMinutes()).slice(-2) + ":" +
                ("0" + gmtMinus4DateTime.getSeconds()).slice(-2);
        }
        return ''
    }

    function convertToInningScoreInfoDict(scoreInfoList) {
        let resumeInnignDict = {}

        scoreInfoList.forEach(item => {
            const { inning, overScoreList } = item
            const inningData = {}

            overScoreList.forEach(overItem => {
                const { over, deliveryScoreList } = overItem
                const overData = {}

                deliveryScoreList.forEach(deliveryItem => {
                    const { delivery, score } = deliveryItem
                    overData[delivery] = score
                })

                inningData[over] = overData
            })

            resumeInnignDict[inning] = inningData
        })

        return resumeInnignDict
    }

    function convertToInningOverConfirmationDict(scoreInfoList) {
        let resumeInnignDict = {}

        scoreInfoList.forEach(item => {
            const { inning, overScoreList } = item
            const inningData = {}

            overScoreList.forEach(overItem => {
                const { over, } = overItem
                inningData[over] = overItem.hasOverConfirmed
            })

            resumeInnignDict[inning] = inningData
        })

        return resumeInnignDict
    }

    function convertToBallRunStatusDict(scoreInfoList) {
        let ballRunStatusDict = {}

        scoreInfoList.forEach(item => {
            const { inning, ballRunStatus } = item
            ballRunStatusDict[inning] = ballRunStatus
        })

        return ballRunStatusDict
    }

    function sortDictionary(dict) {
        return Object.keys(dict).sort((a, b) => {
            const numA = a[0].split('.').map(Number)
            const numB = b[0].split('.').map(Number)

            for (let i = 0; i < Math.min(numA.length, numB.length); i++) {
                if (numA[i] !== numB[i]) {
                    return numA[i] - numB[i]
                }
            }

            return numA.length - numB.length
        })
    }

    function sortDeliveryDictionary(dict) {
        if (dict  === null || dict === undefined){
            dict = {}
        }
        return Object.keys(dict).sort((a, b) => {
            const numA = a.split('.').map(Number)
            const numB = b.split('.').map(Number)

            for (let i = 0; i < Math.min(numA.length, numB.length); i++) {
                if (numA[i] !== numB[i]) {
                    return numA[i] - numB[i]
                }
            }

            return numA.length - numB.length
        })
    }

    function getLatestDeliveryWithScore(overScoreDict) {
        const filteredResult = Object.fromEntries(
            Object.entries(overScoreDict).filter(([_, value]) => value !== '')
        )
        const keys = sortDictionary(filteredResult)
        keys.sort((a, b) => {
            const versionA = a.split('.').map(Number)
            const versionB = b.split('.').map(Number)
          
            for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
              if ((versionA[i] || 0) !== (versionB[i] || 0)) {
                return (versionA[i] || 0) - (versionB[i] || 0)
              }
            }
          
            return 0
          })
        return keys[keys.length - 1]
    }

    function getLatestDeliveryWithNonExtraScore(overScoreDict) {
        const filteredResult = Object.entries(overScoreDict).reduce((result, [key, value]) => {
            Object.entries(value).forEach(([subKey, subValue]) => {
                if (subValue !== undefined && subValue !== '' && !ScoreUtils.isExtraScore(subValue)) {
                    result[subKey] = subValue
                }
            });
            return result
        }, {})

        const keys = sortDeliveryDictionary(filteredResult)
        return keys[keys.length - 1]
    }

    function getLastDelivery(dict) {
        const keys = sortDeliveryDictionary(dict)
        return keys[keys.length - 1]
    }

    const onFocus = (inning, over, delivery, onFocusDelivery) => {
        onScoreInputFocus(inning, over, delivery, onFocusDelivery)

        if (inning in onFocusInputDict) {
            onFocusInputDict[inning] = `scoreInputO${over}D${delivery}`
            setOnFocusInputDict(onFocusInputDict)
        }
        else {
            setOnFocusInputDict({ ...onFocusInputDict, [inning]: `scoreInputO${over}D${delivery}` })
        }
    }

    const updateLastAddedOverDict = (inning, over) => {
        if (inning in lastAddedOverDict) {
            lastAddedOverDict[inning] = over
            setLastAddedOverDict(lastAddedOverDict)
        }
        else {
            setLastAddedOverDict({ ...lastAddedOverDict, [inning]: over  })
        }
    }

    const updateLastAddedDeliveryDict = (inning, delivery) => {
        if (inning in lastAddedDeliveryDict) {
            lastAddedDeliveryDict[inning] = delivery
            setLastAddedDeliveryDict(lastAddedDeliveryDict)
        }
        else {
            setLastAddedDeliveryDict({ ...lastAddedDeliveryDict, [inning]:  delivery })
        }
    }

    const updateFocusDeliveryDict = (inning, focusDelivery) => {
        const updatedDict = { ...focusDeliveryDict }
        updatedDict[inning] = focusDelivery
        setFocusDeliveryDict(updatedDict)
    }

    const onScoreInputFocus = (inning, over, delivery, focusDelivery) => {
        updateLastAddedOverDict(inning, over)
        updateLastAddedDeliveryDict(inning, delivery)
        updateFocusDeliveryDict(inning, focusDelivery)
    }
   
    const isEventInfoValid = () => {
        return eventInfo !== null && eventInfo.eventId !== null && 
        eventInfo.homeTeamId !== null && eventInfo.homeTeamName !== null && 
        eventInfo.awayTeamId !== null && eventInfo.awayTeamName !== null &&
        eventInfo.matchStartTime !== null && eventInfo.competitionCategory !== null && eventInfo.competition !== null
    }

    const onSuperOverChange = (matchPeriod) => {
        setCurrentMatchPeriod(matchPeriod)
        form.setFieldValue('battingTeam', undefined)
        // controlSuperOverToggle()
    }

    const controlSuperOverToggle = () => {
        var superOverMatchPeriod = currentMatchPeriod
        if (superOverMatchPeriod.startsWith(period.so1)) {
            setDisableSOHomeAway(false)
            setSuperOverToggleState([false, true, true])
            if (matchPeriods.length > 2 && matchPeriods[2].matchPeriod === period.home) {
                setSuperOverTeamToggleState([false, true])
            } else {
                setSuperOverTeamToggleState([true, false])
            }
        }
        else if (superOverMatchPeriod.startsWith(period.so2)) {
            if(matchPeriods.length <= 6){
                setSuperOverVal(null)
            }
            setSuperOverToggleState([true, false, true])
            if (matchPeriods.length > 5 && matchPeriods[5].matchPeriod === period.so1Home) {
                setSuperOverTeamToggleState([false, true])
            } else {
                setSuperOverTeamToggleState([true, false])
            }
        }
        else if (superOverMatchPeriod.startsWith(period.so3)) {
            if(matchPeriods.length <= 8){
                setSuperOverVal(null)
            }
            setSuperOverToggleState([true, true, false])
            if (matchPeriods.length > 7 && matchPeriods[7].matchPeriod === period.so2Home) {
                setSuperOverTeamToggleState([false, true])
            } else {
                setSuperOverTeamToggleState([true, false])
            }
        }
    }

    const onSuperOverHAChange = (team) => {
        setBattingTeamToggleState([true, true, true, true])
        let soMatchPeriod = ""
        if (currentMatchPeriod.startsWith(period.so1)) {
            soMatchPeriod = team === period.soHome ? period.so1Home : period.so1Away
        }
        else if (currentMatchPeriod.startsWith(period.so2)) {
            soMatchPeriod = team === period.soHome ? period.so2Home : period.so2Away
        }
        else if (currentMatchPeriod.startsWith(period.so3)) {
            soMatchPeriod = team === period.soHome ? period.so3Home : period.so3Away
        }
        addSoMatchPeriod(soMatchPeriod)
        handleInningChange(soMatchPeriod)
        setSuperOverVal(team)
        setBallRunStatusDict({ ...ballRunStatusDict, [soMatchPeriod]: 0 })
        // setScoreChange(true)
    }

    const controlSuperOverTeamToggle = () => {
        if(matchPeriods.length <= 4){
            return
        }
        setBattingTeamToggleState([true, true, true, true])
        if (currentMatchPeriod.startsWith(period.so1)) {
            setSuperOverToggleState([false, true, true])
            if(matchPeriods[4]?.matchPeriod !== undefined){
                setSuperOverTeamToggleState([false, false])
            }
            if (matchPeriods[5]?.matchPeriod !== undefined) {
                setSuperOverToggleState([false, false, true])
                setSuperOverTeamToggleState([matchPeriods[4]?.matchPeriod === period.so1Home, matchPeriods[4]?.matchPeriod === period.so1Away])
                disableScoreInputControls(matchPeriods[4]?.matchPeriod)
            }
        }
        else if (currentMatchPeriod.startsWith(period.so2)) {
            setSuperOverToggleState([true, false, true])
            disableScoreInputControls(matchPeriods[5]?.matchPeriod)
            if(matchPeriods[6]?.matchPeriod !== undefined){
                setSuperOverTeamToggleState([false, false])
            }
            if (matchPeriods[7]?.matchPeriod !== undefined) {
                setSuperOverToggleState([true, false, false])
                setSuperOverTeamToggleState([matchPeriods[6]?.matchPeriod === period.so2Home, matchPeriods[6]?.matchPeriod === period.so2Away])
                disableScoreInputControls(matchPeriods[6]?.matchPeriod)
            }
        }
        else if (currentMatchPeriod.startsWith(period.so3)) {
            setSuperOverToggleState([true, true, false])
            disableScoreInputControls(matchPeriods[7]?.matchPeriod)
            if(matchPeriods[8]?.matchPeriod !== undefined){
                setSuperOverTeamToggleState([false, false])
            }
            if (matchPeriods[9]?.matchPeriod !== undefined) {
                setSuperOverTeamToggleState([matchPeriods[8]?.matchPeriod === period.so3Home, matchPeriods[8]?.matchPeriod === period.so3Away])
                disableScoreInputControls(matchPeriods[8]?.matchPeriod)
            }
        }
    }

    const addSoMatchPeriod = (matchPeriod) => {
        let filteredMatchPeriod = matchPeriods.filter(mp => mp.matchPeriod !== matchPeriod)
        setMatchPeriods([...filteredMatchPeriod, { matchPeriod }])
        setCurrentBattingTeam(matchPeriod)
    }

    function getLastItemsInDictionary(numOfItems, dict) {
        var topLevelKeys = Object.keys(dict)
        var sortedTopLevelKeys = topLevelKeys
            .sort((a, b) => parseFloat(b) - parseFloat(a))
            .slice(0, 3)

        var latestSixDict = sortedTopLevelKeys.reduce((result, key) => {
            const orderedNestedValues = Object.entries(dict[key])
                .sort((a, b) => parseFloat(a[0].replace(/[^\d.]/g, '')) - parseFloat(b[0].replace(/[^\d.]/g, '')))
                .reduce((obj, [nestedKey, value]) => {
                    if (value !== '') {
                        obj[nestedKey] = value;
                    }
                    return obj
                }, {})

            result[key] = orderedNestedValues
            return result
        }, {})

        let lastSixItems = []

        Object.values(latestSixDict).forEach((innerObj) => {
            const keys = sortDictionary(innerObj)
            const lastSixKeys = keys.slice(-numOfItems)

            lastSixItems = lastSixItems.concat(lastSixKeys.map((key) => innerObj[key]))
        })

        return lastSixItems.slice(-numOfItems)
    }

    function hasMatchStarted(obumEvent) {
        var matchStartTime = new Date(obumEvent.matchStartTime)
        return matchStartTime < Date.now()
    }

    function getUnconfirmedOverScoreList() {
        var overConfirmationStatusList = overConfirmationStatusDict !== null && currentActiveInningTab in overConfirmationStatusDict ? overConfirmationStatusDict[currentActiveInningTab] : { 1: false }
        let overScoreDict = inningOverScoreDict[currentActiveInningTab] ?? { 1: { '0.1.0': '' } }

        return Object.entries(overConfirmationStatusList)
            .filter(([key, value]) => !value)
            .map(([key]) =>
            ({
                over: key,
                hasScoreConfirm: false,
                deliveryScoreList: key in overScoreDict ? Object.entries(overScoreDict[key]).map(([dKey, dValue]) =>
                ({
                    delivery: dKey,
                    score: dValue
                })) : []
            }))
    }

    function getUnconfirmedOverListByInning(inning) {
        var overConfirmationStatusList = overConfirmationStatusDict !== null && inning in overConfirmationStatusDict ? overConfirmationStatusDict[inning] : { 1: false }
        let overScoreDict = inningOverScoreDict[inning] ?? { 1: { '0.1.0': '' } }

        return Object.entries(overConfirmationStatusList)
            .filter(([key, value]) => !value)
            .map(([key]) =>
            ({
                over: key,
                hasScoreConfirm: false,
                deliveryScoreList: key in overScoreDict ? Object.entries(overScoreDict[key]).map(([dKey, dValue]) =>
                ({
                    delivery: dKey,
                    score: dValue
                })) : []
            }))
    }

    function getLatestOverDeliveryWithScore(inning) {
        let overScoreDict = inningOverScoreDict[inning] ?? { 1: { '0.1.0': '' } }

        const filteredResult = Object.entries(overScoreDict).reduce((result, [key, value]) => {
            Object.entries(value).forEach(([subKey, subValue]) => {
                if (subValue !== undefined && subValue !== '' && !ScoreUtils.isExtraScore(subValue)) {
                    result[subKey] = subValue
                }
            });
            return result
        }, {})

        const keys = sortDeliveryDictionary(filteredResult)
        return keys[keys.length - 1]
    }

    const onBallRunStatusClicked = (inning, ballRunStatus) => {
        setBallRunStatusDict({ ...ballRunStatusDict, [inning]: ballRunStatus })
    }

    async function submitScoreInfo() {
        var scoreInfo = constructScoreInfo(currentMatchPeriod)
        if(scoreInfo !== null && scoreInfo !== undefined){
            await submitScoreInfoWithNotification(scoreInfo, `Update score`)
        }
    }

    async function submitScoreInfoWithNotification(scoreInfo, callerAction){
        var isSuccess = await ManualTradingService.updateManualCricketScoreInfo(scoreInfo)
        if (!isSuccess){
            notification['error']({
                message: '',
                description: `Failed to ${callerAction}`,
            })
        }

        return isSuccess
    }

    function constructScoreInfo(inning){
        if(inning.startsWith('SO') && !(inning.endsWith(period.soHome) || inning.endsWith(period.soAway))){
            inning = currentBattingTeam
        }
        let lastAddedOver = lastAddedOverDict[inning]
        let currentOver = lastAddedOver === undefined || Number(lastAddedOver) <= 0 ? 1 : Number(lastAddedOver)
        let lastAddedDelivery = lastAddedDeliveryDict[inning]
        var currentDelivery = ScoreUtils.getDeliveryNumber(lastAddedDelivery)
        currentDelivery = (lastAddedOver === undefined || Number(lastAddedOver) <= 0) && (lastAddedDelivery === undefined || Number(currentDelivery) <= 0)
            ? 1
            : Number(currentOver) > 0 && Number(currentDelivery) === 0 ? 6 : Number(currentDelivery)
        let deliveryWithScore = getLatestOverDeliveryWithScore(inning)

        if (lastAddedOver === null || lastAddedDelivery === null || isResuming || inning === '') {
            return
        }

        let overScoreDict = inningOverScoreDict[inning] ?? { 1: { '0.1.0': '' } }

        return {
            eventId: event.eventId,
            currentInning: inning,
            currentOver: currentOver,
            currentDelivery: currentDelivery,
            completedOver: ScoreUtils.getOverNumber(deliveryWithScore),
            completedDelivery: ScoreUtils.getDeliveryNumber(deliveryWithScore),
            last6DeliveryScoreList: getLastItemsInDictionary(6, overScoreDict),
            totalRuns: ScoreUtils.getInningTotalRuns(overScoreDict),
            totalWicketOut: ScoreUtils.getInningTotalWicketOut(overScoreDict),
            ballRunStatus: ballRunStatusDict[currentActiveInningTab],
            matchStartTime: event.matchStartTime,
            unconfirmedOverScoreList: getUnconfirmedOverListByInning(inning),
            sessionId: AuthService.currentUserValue.sessionId,
            updatedBy: AuthService.currentUserValue.name,
            uiRequestId: Date.now()
        }
    }

    async function submitBallRunStatusChange(inning, ballRunStatus) {
        if(inning === period.ht){
            inning = currentMatchPeriod //HT need to reset ballRunStatus for 1st batting team
        }
        if(inning === period.ft || inning === period.final){
            return
        }

        var scoreInfo = constructScoreInfo(inning)
        if(scoreInfo !== null && scoreInfo !== undefined){
            scoreInfo.ballRunStatus = ballRunStatus

            var callerAction = `Update ${inning} BallRun status`
            var isSuccess = await submitScoreInfoWithNotification(scoreInfo, callerAction)
            if(isSuccess){
                onBallRunStatusClicked(inning, ballRunStatus)
            }
            else{
                var infoKeyValue = {
                    eventId: scoreInfo?.eventId,
                    PropertyToUpdate: 'Inning, BallRunStatus',
                    ValueToUpdate: `${inning}, ${ballRunStatus}`
                }
                LoggingService.logError('submitBallRunStatusChange', infoKeyValue, `Failed to ${callerAction}`)
            }
        }
        else{
            var failInfoKeyValue = {
                eventId: scoreInfo?.eventId
            }
            LoggingService.logError('submitBallRunStatusChange', failInfoKeyValue, `Failed to construct ScoreInfo`)
        }
    }

    async function submitScoreInfoRemoved(updatedDictionary, overWithRemovedScore, isNormalScoreRemoved) {

        var scoreInfo = constructScoreInfo(currentActiveInningTab)
        if(scoreInfo !== null && scoreInfo !== undefined){

            scoreInfo.last6DeliveryScoreList = getLastItemsInDictionary(6, updatedDictionary)
            scoreInfo.totalRuns = ScoreUtils.getInningTotalRuns(updatedDictionary)
            scoreInfo.totalWicketOut = ScoreUtils.getInningTotalWicketOut(updatedDictionary)
            
            assignCurrentOverDelivery(updatedDictionary, scoreInfo)
            assignCompletedOverDelivery(updatedDictionary, scoreInfo)
            
            var overConfirmationStatusList = overConfirmationStatusDict !== null && currentActiveInningTab in overConfirmationStatusDict ? overConfirmationStatusDict[currentActiveInningTab] : { 1: false }
            if(overWithRemovedScore in overConfirmationStatusList){
                overConfirmationStatusList[overWithRemovedScore] = false
            }
            scoreInfo.unconfirmedOverScoreList = getLatestUnconfirmedOverScoreList(updatedDictionary, overConfirmationStatusList)

            var callerAction = `Update Score`
            var isSuccess = await submitScoreInfoWithNotification(scoreInfo, callerAction)
            if(!isSuccess){
                var infoKeyValue = {
                    eventId: scoreInfo?.eventId,
                    PropertyToUpdate: 'ScoreInfo',
                    ValueToUpdate: `${JSON.stringify(scoreInfo)}`
                }
                LoggingService.logError('submitScoreInfoRemoved', infoKeyValue, `Failed to ${callerAction}`)
            }
            
            return isSuccess
        }
        else{
            var failInfoKeyValue = {
                eventId: scoreInfo?.eventId
            }
            LoggingService.logError('submitScoreInfoRemoved', failInfoKeyValue, `Failed to construct ScoreInfo`)
        }
        return false
    }

    async function submitScoreInfoChange(score, inning, over, delivery) {
        var updatedDictionary = constructLatestOverScoreDict(score, inning, over, delivery)

        var scoreInfo = constructScoreInfo(inning)
        if(scoreInfo !== null && scoreInfo !== undefined){
            assignCurrentOverDelivery(updatedDictionary, scoreInfo)
            assignCompletedOverDelivery(updatedDictionary, scoreInfo)

            var overConfirmationStatusList = overConfirmationStatusDict !== null && currentActiveInningTab in overConfirmationStatusDict ? overConfirmationStatusDict[currentActiveInningTab] : { 1: false }
            if(over in overConfirmationStatusList){
                overConfirmationStatusList[over] = false
            }
            scoreInfo.unconfirmedOverScoreList = getLatestUnconfirmedOverScoreList(updatedDictionary, overConfirmationStatusList)

            scoreInfo.last6DeliveryScoreList = getLastItemsInDictionary(6, updatedDictionary)
            scoreInfo.totalRuns = ScoreUtils.getInningTotalRuns(updatedDictionary)
            scoreInfo.totalWicketOut = ScoreUtils.getInningTotalWicketOut(updatedDictionary)
    
            var callerAction = `Update Score`
            var isSuccess = await submitScoreInfoWithNotification(scoreInfo, callerAction)
            if(!isSuccess){
                var infoKeyValue = {
                    eventId: scoreInfo?.eventId,
                    PropertyToUpdate: 'ScoreInfo',
                    ValueToUpdate: `${JSON.stringify(scoreInfo)}`
                }
                LoggingService.logError('submitScoreInfoChange', infoKeyValue, `Failed to ${callerAction}`)
            }
            
            return isSuccess
        }
        else{
            var failInfoKeyValue = {
                eventId: scoreInfo?.eventId
            }
            LoggingService.logError('submitScoreInfoChange', failInfoKeyValue, `Failed to construct ScoreInfo`)
        }
        return false
    }

    async function updateOverConfirmStatus(inning, over, delivery){
        var scoreInfo = constructScoreInfo(inning)
        if(scoreInfo !== null && scoreInfo !== undefined){

            scoreInfo.unconfirmedOverScoreList = [{"over": over,"hasScoreConfirm":false,"deliveryScoreList":[{"delivery":delivery,"score":""}]}]
            scoreInfo.currentOver = over
            scoreInfo.currentDelivery = 1

            var callerAction = `Add over ${over}`
            var isSuccess = await submitScoreInfoWithNotification(scoreInfo, callerAction)
            if(!isSuccess){
                var infoKeyValue = {
                    eventId: scoreInfo?.eventId,
                    PropertyToUpdate: 'ScoreInfo',
                    ValueToUpdate: `${JSON.stringify(scoreInfo)}`
                }
                LoggingService.logError('updateOverConfirmed', infoKeyValue, `Failed to ${callerAction}`)
            }

            return isSuccess
        }
        else{
            var failInfoKeyValue = {
                eventId: scoreInfo?.eventId
            }
            LoggingService.logError('updateOverConfirmed', failInfoKeyValue, `Failed to construct ScoreInfo`)
        }

        return false
    }

    function constructLatestOverScoreDict(score, inning, over, delivery){
        var overScoreDict = inningOverScoreDict[inning] ?? { 1: { '0.1.0': '' } }
        const updatedDictionary = JSON.parse(JSON.stringify(overScoreDict))

        if (over in updatedDictionary) {
            updatedDictionary[over][delivery] = score
        }
        else {
            updatedDictionary[over] = { [delivery]: score }
        }

        if (!ScoreUtils.isLastDelivery(delivery) || (ScoreUtils.isLastDelivery(delivery) && ScoreUtils.isExtraScore(score) && !ScoreUtils.hasExceedExtraLimit(delivery))) {
            var isExtra = ScoreUtils.isExtraScore(score) && !ScoreUtils.hasExceedExtraLimit(delivery)
            var newDelivery = isExtra ? ScoreUtils.increaseExtra(delivery) : ScoreUtils.increaseDelivery(delivery)

            if(!(newDelivery in updatedDictionary[over])){
                updatedDictionary[over][newDelivery] = ''
            }
        }
        

        return updatedDictionary
    }

    function getLatestUnconfirmedOverScoreList(latestOverScoreDict, overConfirmationStatusList) {
        let overScoreDict = latestOverScoreDict ?? { 1: { '0.1.0': '' } }

        return Object.entries(overConfirmationStatusList)
            .filter(([key, value]) => !value)
            .map(([key]) =>
            ({
                over: key,
                hasScoreConfirm: false,
                deliveryScoreList: key in overScoreDict ? Object.entries(overScoreDict[key]).map(([dKey, dValue]) =>
                ({
                    delivery: dKey,
                    score: dValue
                })) : []
            }))
    }

    function assignCurrentOverDelivery(updatedDictionary, scoreInfo) {
        let scoreDictKeys = Object.keys(updatedDictionary)
        let currentOver = scoreDictKeys[scoreDictKeys.length - 1]
        let latestDelivery = getLastDelivery(updatedDictionary[currentOver])
        let currentDelivery = ScoreUtils.getDeliveryNumber(latestDelivery)

        scoreInfo.currentOver = currentOver === undefined || Number(currentOver) <= 0 ? 1 : Number(currentOver)
        scoreInfo.currentDelivery = currentDelivery === undefined || Number(currentDelivery) <= 0 ? 6 : Number(currentDelivery)
    }

    function assignCompletedOverDelivery(updatedDictionary, scoreInfo){
        let latestDeliveryWithScore = getLatestDeliveryWithNonExtraScore(updatedDictionary)
        scoreInfo.completedOver = ScoreUtils.getOverNumber(latestDeliveryWithScore)
        scoreInfo.completedDelivery = ScoreUtils.getDeliveryNumber(latestDeliveryWithScore)
    }

    async function submitResultInfoChange(over, inning, overScoreDict) {

        var callerAction = `Confirm Over${over}`
        var resultInfo = constructManualCricketResultInfo(over, inning, overScoreDict)
        var isSuccess = await submitResultInfoWithNotification(resultInfo, callerAction)

        if (!isSuccess) {
            var infoKeyValue = {
                eventId: resultInfo?.eventId,
                PropertyToUpdate: 'ComfirmOver',
                ValueToUpdate: `${JSON.stringify(resultInfo)}`
            }
            LoggingService.logError('submitResultInfo', infoKeyValue, `Failed to ${callerAction}`)
        }
        return isSuccess
    }

    function constructManualCricketResultInfo(over, inning, overScoreDict) {
        var deliveryScoreList = overScoreDict[over]
        var lastAddedDelivery = lastAddedDeliveryDict[inning]
        var filteredDeliveryScores = Object.entries(deliveryScoreList).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {})
        var inningDetailList = Object.entries(filteredDeliveryScores)
            .map(([key, value], index) => {
                var manualCricketInningDetail = {
                    inningNo: mapObumInning(inning),
                    overNo: over,
                    deliveryNo: ++index,
                    deliveryValue: value
                }
                return manualCricketInningDetail
            })

        return {
            eventId: event.eventId,
            inningNo: mapObumInning(inning),
            battingTeam: mapObumTeamName(inning),
            totalRuns: ScoreUtils.getInningTotalRuns(overScoreDict),
            totalWickets: ScoreUtils.getInningTotalWicketOut(overScoreDict),
            completedOver: ScoreUtils.getOverNumber(lastAddedDelivery),
            completedDelivery: ScoreUtils.getDeliveryNumber(lastAddedDelivery),
            inningDetailList: inningDetailList,
            matchStartTime: event.matchStartTime,
            sessionId: AuthService.currentUserValue.sessionId,
            updatedBy: AuthService.currentUserValue.name,
            inning: inning,
            overToConfirm: Number(over),
            uiRequestId: Date.now()
        }
    }

    async function submitResultInfoWithNotification(resultInfo, callerAction) {
        var isSuccess = await ManualTradingService.updateManualCricketResultInfo(resultInfo)
        if (!isSuccess) {
            notification['error']({
                message: '',
                description: `Failed to ${callerAction}`,
            })
        }

        return isSuccess
    }

    let matchInfoProps = {
        checkScoreInput: checkScoreInput,
        setCheckScoreInput: setCheckScoreInput,
        currentBattingTeam: currentBattingTeam,
        setCurrentBattingTeam: setCurrentBattingTeam,
        period: period,
        obumInningEnum: obumInningEnum,
        mapObumTeamName: mapObumTeamName,
        mapObumInning: mapObumInning,
        matchPeriods: matchPeriods,
        setMatchPeriods: setMatchPeriods,
        inningScoreInfo: inningScoreInfo,
        setInningScoreInfo: setInningScoreInfo,
        currentActiveInningTab: currentActiveInningTab,
        setCurrentActiveInningTab: setCurrentActiveInningTab,
        overConfirmationStatusDict: overConfirmationStatusDict,
        setOverConfirmationStatusDict: setOverConfirmationStatusDict,
        inningOverScoreDict: inningOverScoreDict,
        setInningOverScoreDict: setInningOverScoreDict,
        disableScoreInputDict: disableScoreInputDict,
        setDisableScoreInputDict: setDisableScoreInputDict,
        form: form,
        event: event,
        whoWinTossValue: whoWinTossValue,
        setWhoWinTossValue: setWhoWinTossValue,
        battingTeamToggleState: battingTeamToggleState,
        setBattingTeamToggleState: setBattingTeamToggleState,
        handleInningChange: handleInningChange,
        currentMatchPeriod: currentMatchPeriod,
        setCurrentMatchPeriod: setCurrentMatchPeriod,
        onEnableScoreInputChange: onEnableScoreInputChange,
        onWhoWinTossConfirm: onWhoWinTossConfirm,
        onBattingTeamConfirm: onBattingTeamConfirm,
        superOverToggleState: superOverToggleState,
        setSuperOverToggleState: setSuperOverToggleState,
        fullTime: fullTime,
        setFullTime: setFullTime,
        updateOverScoreDict: updateOverScoreDict,
        isResuming: isResuming,
        finalButtonType: finalButtonType,
        setFinalButtonType: setFinalButtonType,
        onFinalConfirm: onFinalConfirm,
        superOverTeamToggleState: superOverTeamToggleState,
        setSuperOverTeamToggleState: setSuperOverTeamToggleState,
        last6Deliveries: last6Deliveries,
        setLast6Deliveries: setLast6Deliveries,
        updateInningScoreInfo: updateInningScoreInfo,
        sortDictionary: sortDictionary,
        sortDeliveryDictionary: sortDeliveryDictionary,
        constructLastSixDeliveriesData: constructLastSixDeliveriesData,
        onFocus: onFocus,
        getScoreInfo: getScoreInfo,
        lastAddedOverDict: lastAddedOverDict,
        setLastAddedOverDict: setLastAddedOverDict,
        lastAddedDeliveryDict: lastAddedDeliveryDict,
        setLastAddedDeliveryDict: setLastAddedDeliveryDict,
        focusDeliveryDict: focusDeliveryDict,
        setFocusDeliveryDict: setFocusDeliveryDict,
        onFocusInputDict: onFocusInputDict,
        setOnFocusInputDict: setOnFocusInputDict,
        updateLastAddedOverDict: updateLastAddedOverDict,
        updateLastAddedDeliveryDict: updateLastAddedDeliveryDict,
        updateFocusDeliveryDict: updateFocusDeliveryDict,
        setIsLoading: setIsLoading,
        onSuperOverChange: onSuperOverChange,
        onSuperOverHAChange: onSuperOverHAChange,
        disableSOHomeAway: disableSOHomeAway,
        superOverVal: superOverVal,
        scoreChange: scoreChange,
        setScoreChange: setScoreChange,
        getLastItemsInDictionary: getLastItemsInDictionary,
        setDisableWhoWinToss: setDisableWhoWinToss,
        disableWhoWinToss: disableWhoWinToss,
        eventInfo: eventInfo,
        getLatestDeliveryWithScore: getLatestDeliveryWithScore,
        disableScoreInput: disableScoreInput,
        setDisableScoreInput: setDisableScoreInput,
        hasMatchStarted: hasMatchStarted,
        ballRunStatusDict: ballRunStatusDict,
        setBallRunStatusDict: setBallRunStatusDict,
        scoreInfoChanged: scoreInfoChanged,
        setScoreInfoChanged: setScoreInfoChanged,
        submitBallRunStatusChange: submitBallRunStatusChange,
        submitScoreInfoChange: submitScoreInfoChange,
        submitResultInfoChange: submitResultInfoChange,
        updateOverConfirmStatus: updateOverConfirmStatus,
        submitScoreInfo: submitScoreInfo,
        submitScoreInfoRemoved: submitScoreInfoRemoved,
        getWinTheTossTeam: getWinTheTossTeam
    }

    useEffect(() => {
        submitScoreInfo()
    }, [superOverVal])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        controlBattingTeamButtons(currentMatchPeriod)
        if((currentMatchPeriod.startsWith('SO')) || superOverVal !== null){
            controlSuperOverToggle()
            controlSuperOverTeamToggle()
        }
    }, [matchPeriods, currentMatchPeriod, superOverVal]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function resumeManualCricketInfo(obumEvent) {
            setIsLoading(true)
            var resumeInfo = await ManualTradingService.resumeManualCricketInfo(obumEvent.eventId, AuthService?.currentUserValue?.sessionId, AuthService?.currentUserValue?.name)
            if(resumeInfo !== null && resumeInfo !== undefined){
                setIsResuming(true)
                if (resumeInfo.matchInfo !== null){
                    setEvent(resumeInfo.matchInfo)
                    form.setFieldsValue(resumeInfo.matchInfo)
                    form.setFieldValue("matchStartTime", formatGmtMinus4DateTime(obumEvent.matchStartTime))

                    onEnableScoreInputChange(resumeInfo.matchInfo.isManualScoreInputEnabled)
                    setDisableScoreInput(!resumeInfo.matchInfo.isManualScoreInputEnabled && (hasMatchStarted(obumEvent) || resumeInfo.matchInfo.winTheToss !== null))

                    if(resumeInfo.matchInfo.winTheToss !== null && resumeInfo.matchInfo.winTheToss !== ''){
                        onWhoWinTossConfirm(resumeInfo.matchInfo.winTheToss)
                    }
                    else{
                        form.setFieldValue("winTheToss", undefined)
                    }

                    let resumeDisableScoreInputDict = { ...disableScoreInputDict } 
                    if(resumeInfo.matchInfo.matchPeriodList !== null && resumeInfo.matchInfo.matchPeriodList.length > 0){
                        resumeInfo.matchInfo.matchPeriodList.forEach((matchPeriodObj, index) => {
                            var inning = matchPeriodObj.matchPeriod
                            var currentBattingTeamName = getBattingTeamName(inning, resumeInfo?.matchInfo?.homeTeamName, resumeInfo?.matchInfo?.awayTeamName)
                            onBattingTeamConfirm(inning, currentBattingTeamName)
                            var arrIndex = null
                            if(inning === period.ht){
                                arrIndex = 0
                            }
                            else if(inning === period.ft){
                                arrIndex = 2
                            }

                            if(arrIndex !== null){
                                resumeDisableScoreInputDict[resumeInfo.matchInfo.matchPeriodList[arrIndex].matchPeriod] = true
                            }
                        })
                        setMatchPeriods(resumeInfo.matchInfo.matchPeriodList)
                        setDisableScoreInputDict(resumeDisableScoreInputDict)
                    }

                    let resumeInningScoreInfo = {}
                    if (resumeInfo.scoreInfoList !== null && resumeInfo.scoreInfoList.length > 0) {

                        var resumeInningOverConfirmationDict = convertToInningOverConfirmationDict(resumeInfo.scoreInfoList)
                        setOverConfirmationStatusDict(resumeInningOverConfirmationDict)

                        var resumeBallRunStatusDict = convertToBallRunStatusDict(resumeInfo.scoreInfoList)
                        setBallRunStatusDict(resumeBallRunStatusDict)

                        var resumeInningScoreDict = convertToInningScoreInfoDict(resumeInfo.scoreInfoList)
                        let lastDeliveryWithScore = ''

                        Object.entries(resumeInningScoreDict).forEach(([inning, overScoreList]) => {
                            Object.entries(overScoreList).forEach(([over, scoreList]) => {
                                var lastDelivery = getLastDelivery(scoreList)
                                onFocus(inning, over, lastDelivery, lastDelivery)
                                updateFocusDeliveryDict(inning, lastDelivery)
                                updateLastAddedDeliveryDict(inning, lastDelivery)

                                Object.entries(scoreList).forEach(([delivery, value]) => {
                                    updateOverScoreDict(inning, over, delivery.toString(), value)
                                })

                                var lastOverDeliveryWithScore = getLatestDeliveryWithScore(scoreList)
                                if(lastOverDeliveryWithScore !== null && lastOverDeliveryWithScore !== undefined){
                                    lastDeliveryWithScore = lastOverDeliveryWithScore
                                }
                            })

                            var last6Deliveries = constructLastSixDeliveriesData(resumeInfo.last6Deliveries)
                            setLast6Deliveries(last6Deliveries)

                            var scoreInfo = getScoreInfo(overScoreList, ScoreUtils.getDisplayDelivery(lastDeliveryWithScore))
                            resumeInningScoreInfo[inning] = scoreInfo
                        })
                        setInningOverScoreDict(resumeInningScoreDict)
                    }

                    if (resumeInfo.matchInfo.battingTeam !== null && resumeInfo.matchInfo.battingTeam !== '') {
                        var battingTeamName = getBattingTeamName(resumeInfo.matchInfo.battingTeam, resumeInfo.matchInfo.homeTeamName, resumeInfo.matchInfo.awayTeamName)
                        setCurrentBattingTeam(battingTeamName)

                        var activeInning = resumeInfo.matchInfo.battingTeam 
                        if(resumeInfo.matchInfo.battingTeam === 'HT' || resumeInfo.matchInfo.battingTeam === 'FT' || resumeInfo.matchInfo.battingTeam === 'Final' || (resumeInfo.matchInfo.superOver !== null && resumeInfo.matchInfo.superOverHA === null)){
                            activeInning = Object.keys(resumeInningScoreDict).pop()
                        }
                        else if (resumeInfo.matchInfo.superOver !== null && resumeInfo.matchInfo.superOverHA !== null){
                            activeInning = `${resumeInfo.matchInfo.superOver} ${resumeInfo.matchInfo.superOverHA}`
                        }

                        setCurrentActiveInningTab(activeInning)
                    }
                    setInningScoreInfo(resumeInningScoreInfo)

                    if(resumeInfo.matchInfo.superOver !== null && resumeInfo.matchInfo.superOver !== undefined){
                        setCurrentMatchPeriod(resumeInfo.matchInfo.superOver)
                    }

                    if(resumeInfo.matchInfo.superOverHA !== null  && resumeInfo.matchInfo.superOverHA !== undefined){
                        setSuperOverVal(resumeInfo.matchInfo.superOverHA)
                        // setCurrentMatchPeriod(resumeInfo.matchInfo.superOverHA)
                    }

                    if(resumeInfo.matchInfo.isMatchFinal){
                        setFullTime(true)
                        onFinalConfirm()
                    }
                }
                
                setIsResuming(false)
            }
            else{
                setEvent(obumEvent)
                form.setFieldsValue(obumEvent)
                form.setFieldValue("matchStartTime", formatGmtMinus4DateTime(obumEvent.matchStartTime))
                setDisableScoreInput(hasMatchStarted(obumEvent))
            }
            
            setIsLoading(false)
        }
    
        if(isEventInfoValid()){
            resumeManualCricketInfo(eventInfo)
        }
        
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    return (
            isEventInfoValid()
            ? 
            (
                <Spin spinning={isLoading} size="large" tip={<h1><br/>Loading ...</h1>}>
                    <div style={{ margin: '25px' }}>
                        <Row gutter={[25, 25]}>
                            <Col span={10}><MatchInfoInput props={matchInfoProps} /></Col>
                            {currentBattingTeam !== '' ? < Col span={14}><ScoreInfoInput props={matchInfoProps} /></Col> : <></>}
                        </Row>
                    </div>
                </Spin>
            )
            : (<Result
            status="warning"
            title={
                <>
                    Missing param when accessing manual score input page.<br /><br />
                    <p style={{fontSize: '15px'}}>{`Param:  ${JSON.stringify(eventInfo)}`}</p>
                </>
            }/>)
    )
}
