import { Row, Col, Button } from 'antd'
import React from 'react'
import './ScoreNumPad.css'

export const ScoreNumPad = ({ onScoreClicked, onBallRunStatusClicked, ballRunStatus }) => {
    const scoreBtnStyle = {
        margin: '3px 3px 0px 0px',
        width: '25%'
    }
    const ballRunBtnStyle = {
        margin: '3px 3px 0px 0px',
        width: '50%'
    }
    const safeBtnStyle = {
        margin: '3px 3px 0px 0px',
        width: '50%',
    }

    return (
        // <Form form={form} name='scoreInputControl'>
        <div>
            <Row>
                <Col span={24}>
                    <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                    >
                        <h3>Score Input</h3>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={4}></Col>
                <Col span={20}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button type="primary" className={ballRunStatus === 1 ? "red-button" : ""} style={ballRunBtnStyle} onClick={() => onBallRunStatusClicked(1)} >BALL RUN</Button>
                        <Button type="primary" className={ballRunStatus === 0 ? "green-button" : ""} style={safeBtnStyle} onClick={() => onBallRunStatusClicked(0)} >SAFE</Button>
                    </div>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col span={4} >Runs:</Col>
                <Col span={20}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("0")}>0</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("1")}>1</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("2")}>2</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("3")}>3</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={4} ></Col>
                <Col span={20}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'left'
                    }}>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("4")}>4</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("5")}>5</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("6")}>6</Button>
                        <Button type="primary" style={scoreBtnStyle}></Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={4}></Col>
                <Col span={20}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("1lb")}>1lb</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("2lb")}>2lb</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("3lb")}>3lb</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("4lb")}>4lb</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={4}></Col>
                <Col span={20}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'left'
                    }}>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("5lb")}>5lb</Button>
                        <Button type="primary" style={scoreBtnStyle}></Button>
                        <Button type="primary" style={scoreBtnStyle}></Button>
                        <Button type="primary" style={scoreBtnStyle}></Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={4}></Col>
                <Col span={20}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("1b")}>1b</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("2b")}>2b</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("3b")}>3b</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("4b")}>4b</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={4}></Col>
                <Col span={20}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'left'
                    }}>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("5b")}>5b</Button>
                        <Button type="primary" style={scoreBtnStyle}></Button>
                        <Button type="primary" style={scoreBtnStyle}></Button>
                        <Button type="primary" style={scoreBtnStyle}></Button>
                    </div>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col span={4}>Extras:</Col>
                <Col span={20}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("1w")}>1w</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("2w")}>2w</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("3w")}>3w</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("4w")}>4w</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={4}></Col>
                <Col span={20}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'left'
                    }}>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("5w")}>5w</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("6w")}>6w</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("7w")}>7w</Button>
                        <Button type="primary" style={scoreBtnStyle}></Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={4}></Col>
                <Col span={20}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("1n")}>1n</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("2n")}>2n</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("3n")}>3n</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("4n")}>4n</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={4}></Col>
                <Col span={20}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'left'
                    }}>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("5n")}>5n</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("6n")}>6n</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("7n")}>7n</Button>
                        <Button type="primary" style={scoreBtnStyle}></Button>
                    </div>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col span={4}>Wicket:</Col>
                <Col span={20}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("w")}>w</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("w1")}>w1</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("w2")}>w2</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("w3")}>w3</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={4}></Col>
                <Col span={20}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("w4")}>w4</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("w5")}>w5</Button>
                        <Button type="primary" style={scoreBtnStyle} onClick={() => onScoreClicked("w6")}>w6</Button>
                        <Button type="primary" style={scoreBtnStyle}></Button>
                    </div>
                </Col>
            </Row>
        </div>
        // </Form>
    )
}
