import React from 'react'
import { Result } from 'antd'

export const UnauthorizedAccessPage = (props) => {
  return (
    <div style={{margin: 'auto'}}>
      <Result
        status="warning"
        title="Unauthorized Access"/>
    </div>
  )
}