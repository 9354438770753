import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { ConfigProvider, Layout } from 'antd'
import { validateMessages } from './ValidateMessages'
import './axios'
import './App.less'
import { ManualTradingInputPage } from './pages/ManualTrading'
import { LoadingPage, UnauthorizedAccessPage } from './pages'
import { AuthService } from './services'
import { skip } from 'rxjs/operators'
import AppBoundary from './AppBoudary'

export default function App() {

  const history = useHistory()

  const getEventId = () => {
    const url = new URL(window.location.href)
    return url?.searchParams?.get('eventId')
  }

  const getEventInfo = () => {
    const url = new URL(window.location.href)
    const eventId = url.searchParams.get('eventId')
    const homeTeamName = url.searchParams.get('homeTeamName')
    const homeTeamId = url.searchParams.get('homeTeamId')
    const awayTeamName = url.searchParams.get('awayTeamName')
    const awayTeamId = url.searchParams.get('awayTeamId')
    let matchStartTime = url.searchParams.get('matchStartTime')
    const competitionCategory = url.searchParams.get('competitionCategory')
    const competition = url.searchParams.get('competition')

    if (matchStartTime.includes(' ') && !matchStartTime.includes('+')) {
      matchStartTime = matchStartTime.replace(/ /g, '+');
    }

    return {
      eventId: eventId,
      homeTeamName: homeTeamName,
      homeTeamId: homeTeamId,
      awayTeamName: awayTeamName,
      awayTeamId: awayTeamId,
      matchStartTime: matchStartTime,
      competitionCategory: competitionCategory,
      competition: competition
    }
  }

  if (window.location.pathname === '/authenticate') {
    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')
    const isAdmin = url.searchParams.get('isAdmin') === 'true' ? true : false

    if (token || isAdmin) {
      const decodedToken = decodeURIComponent(token)
      AuthService.loginWithToken(decodedToken, isAdmin).catch((_) => {
        setTimeout(() => {
          history.push('/unauthorized')
        }, 1000)
      })
    } else {
      setTimeout(() => {
        history.push('/unauthorized')
      }, 1500)
    }
  }

  AuthService.currentUser.pipe(skip(1)).subscribe((user) => {
    const currentPath = history.location.pathname
    if (!user && currentPath !== '/login' && currentPath !== '/authenticate') {
      history.push('/unauthorized')
    } else if (user && (currentPath === '/login' || currentPath === '/authenticate')) {
      history.push('/', getEventInfo())
    }
  })

  return (
    <AppBoundary eventId={getEventId()}>
      <Layout className="app-layout">
        <ConfigProvider form={{ validateMessages }}>
          <Switch>
            <Route exact path="/unauthorized" component={UnauthorizedAccessPage} />
            <Route exact path="/authenticate" component={LoadingPage} />

            <Route exact path="/" component={ManualTradingInputPage} />
          </Switch>
        </ConfigProvider>
      </Layout>
    </AppBoundary>
  )
}
