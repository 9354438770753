function getInningTotalRuns(overScoreDict) {
    let sum = 0

    Object.keys(overScoreDict).forEach(key => {
        Object.keys(overScoreDict[key]).forEach(innerKey => {
            var score = overScoreDict[key][innerKey]
            sum += sumDigits(score)
        })
    })

    return sum
}

function getOverTotalRuns(overScoreDict) {
    let sum = 0

    Object.keys(overScoreDict).forEach(key => {
        var score = overScoreDict[key]
        sum += sumDigits(score)
    })

    return sum
}

function getInningTotalWicketOut(overScoreDict) {
    let sum = 0

    Object.keys(overScoreDict).forEach(key => {
        Object.keys(overScoreDict[key]).forEach(innerKey => {
            var score = overScoreDict[key][innerKey]
            sum += score.startsWith('w') ? 1 : 0
        })
    })

    return sum
}

function getOverTotalWicketOut(overScoreDict) {
    let sum = 0

    Object.keys(overScoreDict).forEach(key => {
        var score = overScoreDict[key]
        sum += score.startsWith('w') ? 1 : 0
    })

    return sum
}

function isExtraScore(score){
    return /^\d[wn]$/.test(score)
}

function sumDigits(str) {
    const digits = str.match(/\d/g)
    if (digits) {
      return digits.reduce((acc, val) => acc + parseInt(val, 10), 0)
    }
    return 0
}

function getDisplayDelivery(delivery) {
    if(delivery === null || delivery === undefined || delivery === ''){
        return '0.0'
    }
    const [completedOver, completedDelivery,] = delivery.split('.').map(Number)
    return `${completedOver}.${completedDelivery}`
}

function getDeliveryNumber(delivery) {
    if(delivery === null || delivery === undefined){
        return 0
    }
    const [, deliveryNo,] = delivery.split('.').map(Number)
    return deliveryNo
}

function getOverNumber(delivery) {
    if(delivery === null || delivery === undefined){
        return 0
    }
    const [overNo,,] = delivery.split('.').map(Number)
    return overNo
}

function getExtraNumber(delivery) {
    if(delivery === null || delivery === undefined){
        return 0
    }
    const [,,extraNo] = delivery.split('.').map(Number)
    return extraNo
}

function hasExceedExtraLimit(delivery){
    const [,, extraNo] = delivery.split('.').map(Number)
    return extraNo >= 14
}

function hasScoreEntered(scoreDict){
    return Object.values(scoreDict).some(value => value !== '')
}

function hasAnyUnconfirmOver(overConfirmationStatusList, currentOver) {
    if (Object.entries(overConfirmationStatusList).length === 1) {
        return false
    }
    let dict = { ...overConfirmationStatusList }
    dict = Object.entries(dict)
        .filter(([key, val]) => Number(key) < currentOver)
        .map(([key, val]) => { return val });

    return !dict.every(status => status === true);
}

function increaseExtra(currentDelivery) {
    const [completedOver, completedDelivery, extraNo] = currentDelivery.split('.').map(Number)

    const newExtraNo = extraNo + 1

    return `${completedOver}.${completedDelivery}.${newExtraNo}`
}

function increaseDelivery(currentDelivery) {
    const [completedOver, completedDelivery, ] = currentDelivery.split('.').map(Number)

    var newCompletedOver = completedOver
    var newCompletedDelivery = completedDelivery
    if (completedDelivery === 5) {
        newCompletedDelivery = 0
        newCompletedOver = newCompletedOver + 1
    }
    else {
        newCompletedDelivery = newCompletedDelivery + 1
    }

    return `${newCompletedOver}.${newCompletedDelivery}.0`
}

function isLastDelivery(delivery, score) {
    const [, completedDelivery,] = delivery.split('.').map(Number)

    return completedDelivery === 0 && (!ScoreUtils.isExtraScore(score) || ScoreUtils.hasExceedExtraLimit(delivery))
}

export const ScoreUtils = {
    getInningTotalRuns,
    getOverTotalRuns,
    getInningTotalWicketOut,
    getOverTotalWicketOut,
    isExtraScore,
    getDisplayDelivery,
    getDeliveryNumber,
    getOverNumber,
    getExtraNumber,
    hasExceedExtraLimit,
    hasScoreEntered,
    hasAnyUnconfirmOver,
    increaseExtra,
    increaseDelivery,
    isLastDelivery
  }
