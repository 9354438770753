import { Tabs, Row, Col, Tag, Divider, Table } from 'antd'
import { InningTabItem } from '.'
import React from 'react'
import './ScoreInfoInput.css'

export const ScoreInfoInput = ({ props }) => {

    let period = props.period
    let inningList = props.matchPeriods
    let inningScoreInfo = props.inningScoreInfo
    let currentActiveInningTab = props.currentActiveInningTab
    let setCurrentActiveInningTab = props.setCurrentActiveInningTab
    let last6Deliveries = props.last6Deliveries
    let updateInningScoreInfo = props.updateInningScoreInfo
    let setLast6Deliveries = props.setLast6Deliveries
    let inningOverScoreDict = props.inningOverScoreDict
    // let sortDictionary = props.sortDictionary
    let constructLastSixDeliveriesData = props.constructLastSixDeliveriesData
    let getLastItemsInDictionary = props.getLastItemsInDictionary

    const tagContentStyle = {
        display: 'flex',
        width: '100%',
        height: '100%',
    }

    const customTableStyle = {
        border: '1px solid #d9d9d9',
        borderRadius: '5px'
    }

    const columns = [
        {
            title: 'D1',
            dataIndex: 'd1',
            key: 'd1',
            align: 'center',
            width: '16%'
        },
        {
            title: 'D2',
            dataIndex: 'd2',
            key: 'd2',
            align: 'center',
            width: '16%'
        },
        {
            title: 'D3',
            dataIndex: 'd3',
            key: 'd3',
            align: 'center',
            width: '16%'
        },
        {
            title: 'D4',
            dataIndex: 'd4',
            key: 'd4',
            align: 'center',
            width: '16%'
        },
        {
            title: 'D5',
            dataIndex: 'd5',
            key: 'd5',
            align: 'center',
            width: '16%'
        },
        {
            title: 'D6',
            dataIndex: 'd6',
            key: 'd6',
            align: 'center',
            width: '16%'
        },
    ]

    const tabOnChange = (key) => {
        setCurrentActiveInningTab(key)
        setLast6Deliveries(constructLastSixDeliveriesData(getLastItemsInDictionary(6, inningOverScoreDict[key])))
    }

    return (
        <div>
            <Row>
                <Col span={8}>
                    <Tag style={{ width: '100%' }}>
                        <div style={tagContentStyle}>
                            <h3>1st Inning Home: {inningScoreInfo.hasOwnProperty(period.home) ? inningScoreInfo[period.home] : 'Ytb'}</h3>
                        </div>
                    </Tag>
                </Col>
                <Col span={8}>
                    <Tag style={{ width: '100%' }}>
                        <div style={tagContentStyle}>
                            <h3>1st Inning Away: {inningScoreInfo.hasOwnProperty(period.away) ? inningScoreInfo[period.away] : 'Ytb'}</h3>
                        </div>
                    </Tag>
                </Col>
                <Col span={8}></Col>
            </Row>

            <Divider style={{ margin: '15px 0px 15px 0px' }}/>

            <Row>
                <Col span={16}>
                    <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                    >
                        <h3>Last 6 Deliveries</h3>
                    </div>
                </Col>
                <Col span={8}></Col>
            </Row>
            <Row>
                <Col span={16}>
                    <Table columns={columns} dataSource={last6Deliveries} size="small" showHeader={false} pagination={false} bordered style={customTableStyle}/>
                </Col>
                <Col span={8}></Col>
            </Row>

            <Divider style={{ margin: '15px 0px 15px 0px'}}/>

            <div className="card-container" style={{marginTop: '15px'}}>
                <Tabs
                    defaultActiveKey="1"
                    activeKey={currentActiveInningTab}
                    tabPosition="left"
                    type="card"
                    size="small"
                    onChange={tabOnChange}
                    items={inningList
                        .filter((inning) =>
                            inning.matchPeriod !== period.ht &&
                            inning.matchPeriod !== period.ft &&
                            inning.matchPeriod !== period.final
                        )
                        .map((inning) => ({
                        key: inning.matchPeriod,
                        label: (
                            <div style={{ minWidth: '105px' }}>
                                <div>{inning.matchPeriod}</div>
                                <div>{inningScoreInfo[inning.matchPeriod]}</div>
                            </div>
                        ),
                        children: (
                            <InningTabItem
                            inning={inning.matchPeriod}
                            updateInningScoreInfo={updateInningScoreInfo}
                            props={props}
                            />
                        ),
                    }))}
                />
            </div>
        </div>
        
    )
        
}
