import { BehaviorSubject } from 'rxjs'
import { SyncController } from './SyncController'
import { UserInfo } from './UserInfo'
import axios from 'axios'

let _refreshTokenTimeout

const currentUserSubject = new BehaviorSubject(getUser())
const userSyncController = SyncController('user', (curr, prev, url) => {
  const user = new UserInfo(curr)
  currentUserSubject.next(isExpired(user?.exp) ? null : curr)
})

currentUserSubject.subscribe((user) => {
  userSyncController.trigger(user)
  stopRefreshTokenTimer()
  startRefreshTokenTimer(user)
})

function getUser() {
  if (!localStorage.user) {
    return null
  } else {
    const user = new UserInfo(JSON.parse(localStorage.user))
    return isExpired(user?.exp) ? null : user
  }
}

async function getProfile() {
  var response = await axios.get('account/profile').catch(console.log)
  return response?.data
}

async function updateProfile(data) {
  data.refreshToken = currentUserSubject.value?.refreshToken?.token
  var response = await axios.post('account/profile', data).catch(console.log)
  if (!response?.data?.isError) {
    var user = new UserInfo(response?.data?.result)
    currentUserSubject.next(user)
    refreshToken(user.refreshToken.token)
  }
  return response?.data
}

async function changePassword(data) {
  try {
    var response = await axios.post('account/changePassword', data).catch(console.log)
    return response?.data
  } catch (error) {
    return error.response?.data
  }
}

async function login(userName, password) {
  try {
    const response = await axios.post('account/login', { userName, password }).catch(console.log)
    if (response?.data?.result?.succeeded) {
      var user = new UserInfo(response?.data?.result)
      currentUserSubject.next(user)
    }
    return response?.data
  } catch (error) {
    return error.response?.data
  }
}

async function loginWithToken(token, isAdmin) {
  try {
    logout()
    const response = await axios.post('account/authenticate', { token, isAdmin }).catch(console.log)
    if (response?.data?.result?.succeeded) {
      var user = new UserInfo(response?.data?.result)
      currentUserSubject.next(user)
    }
    return response?.data
  } catch (error) {
    return error.response?.data
  }
}

function logout() {
  stopRefreshTokenTimer()
  currentUserSubject.next(null)
}

function startRefreshTokenTimer(user, timeout) {
  if (!user) return
  if (!timeout) {
    const exp = user?.exp
    const expDate = new Date(0)
    expDate.setUTCSeconds(exp)
    var buffer = [70000, 50000, 30000][Math.floor(Math.random() * 3)]
    timeout = expDate - Date.now() - buffer
  }

  _refreshTokenTimeout = setTimeout(() => refreshToken(user.refreshToken.token), timeout)
}

function stopRefreshTokenTimer() {
  clearTimeout(_refreshTokenTimeout)
}

async function refreshToken(token) {
  const response = await axios.post('account/refresh-token?token=' + encodeURIComponent(token)).catch(console.log)
  const result = response?.data?.result
  if (result?.succeeded) {
    var userInfo = new UserInfo(result)
    currentUserSubject.next(userInfo)
  } else {
    logout()
  }
}

function isExpired(exp, offsetSeconds) {
  const expDate = new Date(0)
  expDate.setUTCSeconds(exp)
  if (expDate === null) {
    return false
  }
  if (!offsetSeconds) offsetSeconds = 0
  return !(expDate.valueOf() > new Date().valueOf() + offsetSeconds * 1000)
}

export const AuthService = {
  login,
  loginWithToken,
  logout,
  getProfile,
  updateProfile,
  changePassword,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value
  }
}
