import { useEffect } from 'react'
import { LoggingService } from './services'

function AppBoundary({ children, eventId }) {

  useEffect(() => {
    var infoKeyValue = {
      eventId: eventId
    }
    LoggingService.logInfo('Client connected to EmWeb', infoKeyValue, '')

    const handleError = (error) => {
        var infoKeyValue = {}
        if(error !== null && error !== undefined){
            if(error.error?.stack !== null && error.error?.stack !== undefined){
                infoKeyValue = {
                    stacktrace: error.error.stack
                }
            }
            LoggingService.logError('Unhandled Exception', infoKeyValue, JSON.stringify(error.message)) 
        }
    }

    const timer = setInterval(() => {
      LoggingService.logInfo('Client Hearbeat', infoKeyValue, '') 
    }, 15000)

    window.addEventListener('error', handleError)

    return () => {
      window.removeEventListener('error', handleError)
      clearInterval(timer)
    }
  }, [])

  return children
}

export default AppBoundary