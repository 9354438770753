import React  from 'react'
import { Collapse, Input, Button, Row, Col, Tag } from 'antd'
import { ScoreUtils } from './'
import { MinusCircleOutlined } from '@ant-design/icons'

export const OverScorePanel = ({ inning, overScoreDict, currentExpandOver, onChangeCollapse, scoreInputRef, onOverConfirmed, overConfirmationStatusList, onRemoveScore, disableConfirmBtn, onFocus, onFocusInputDict, getLastDeliveryWithScore }) => {

    const { Panel } = Collapse

    let focusScoreInput = onFocusInputDict[inning]

    function isLastDeliveryWithScore(scoreDict, currentDelivery){
        var lastDeliveryWithScore = getLastDeliveryWithScore(scoreDict)

        return lastDeliveryWithScore === currentDelivery
    }
       
    return (
        <Collapse activeKey={currentExpandOver} onChange={onChangeCollapse}>
        {
            Object.entries(overScoreDict).map(([over, scoreDict]) => (
                <Panel header={
                    <Row>
                        <Col span={6}>{`Over ${over}`}</Col>
                        <Col span={8} offset={2}>
                            {
                                <>
                                    <span><b>{`${ScoreUtils.getOverTotalRuns(scoreDict)}`}</b></span>
                                    {`/${ScoreUtils.getOverTotalWicketOut(scoreDict)}`}
                                </>
                            }</Col>
                        <Col span={6}>{overConfirmationStatusList[over] === false && Object.entries(overConfirmationStatusList).length !== Number(over) ? <Tag color="red">Unconfirmed</Tag> : ''}</Col>
                    </Row>
                } key={over} showArrow={false} style={{backgroundColor: overConfirmationStatusList.hasOwnProperty(over) && overConfirmationStatusList[over] ? '#daebfa' : ''}}>
                    {
                        Object.entries(scoreDict).sort((a, b) => {
                            const numA = a[0].split('.').map(Number)
                            const numB = b[0].split('.').map(Number)

                            for (let i = 0; i < Math.min(numA.length, numB.length); i++) {
                                if (numA[i] !== numB[i]) {
                                    return numA[i] - numB[i]
                                }
                            }

                            return numA.length - numB.length
                          }).map(([delivery, score], index) => (
                            <Row key={`${over}-${index}`}>
                                <Col span={10}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: '4px 11px',
                                        height: '32px',
                                        borderTop: index === 0 ? '1px solid #d9d9d9' : '1px solid white',
                                        borderBottom: ScoreUtils.isExtraScore(score) && !ScoreUtils.hasExceedExtraLimit(delivery) ? '1px solid white' : '1px solid #d9d9d9',
                                        borderLeft: '1px solid #d9d9d9',
                                        borderRight: '1px solid #d9d9d9'

                                    }}>
                                        {ScoreUtils.isExtraScore(score) && !ScoreUtils.hasExceedExtraLimit(delivery) ? ' ' : ScoreUtils.getDisplayDelivery(delivery)}
                                        {/* {delivery} */}
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <Input style={
                                        {
                                            background: focusScoreInput === `scoreInputO${over}D${delivery}` ? '#ecf5fd' : 'white', 
                                            boxShadow: focusScoreInput === `scoreInputO${over}D${delivery}` ? '0 0 0 2px rgba(24, 144, 255, 0.2)' : 'none',
                                            borderColor: focusScoreInput === `scoreInputO${over}D${delivery}` ? '#40a9ff' : '#d9d9d9'
                                        }
                                    } 
                                    readOnly value={score} id={delivery} 
                                    // disabled={overConfirmationStatusList.hasOwnProperty(over) && overConfirmationStatusList[over]}
                                    onFocus={(e) => onFocus(inning, over, delivery, e.target.id)}
                                    ref={(el) => scoreInputRef.current[delivery] = el}
                                    />
                                    
                                </Col>
                                <Col span={2}>
                                    {
                                        ScoreUtils.isExtraScore(score) || isLastDeliveryWithScore(scoreDict, delivery)
                                        ? 
                                        <Button
                                            type="link"
                                            disabled={disableConfirmBtn}
                                            //disabled={!disableConfirmBtn ? ScoreUtils.isExtraScore(score) ? false : overConfirmationStatusList.hasOwnProperty(over) && overConfirmationStatusList[over] : false }
                                            icon={<MinusCircleOutlined style={{ color: 'red' }} />}
                                            onClick={() => {onRemoveScore(over, delivery, score)}}
                                        /> 
                                        : <></>
                                    }
                                </Col>
                            </Row>
                        ))
                    }
                    <br/>
                    <Row justify="end">
                        <Col span={12}><span></span></Col>
                        <Col span={12}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'right'
                            }}>
                                <Button type="primary" onClick={() => onOverConfirmed(over)} disabled={!ScoreUtils.hasScoreEntered(scoreDict) || disableConfirmBtn}>Confirm</Button>
                            </div>
                        </Col>
                    </Row>
                </Panel>
            ))
        }
        </Collapse>
        
    )
}
