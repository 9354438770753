import { Form, Row, Col, Checkbox, Select, Radio, Button, Modal, Typography, Input, notification } from 'antd'
import React, { useEffect } from 'react'
import { ManualTradingService, AuthService, LoggingService } from '../../services'

export const MatchInfoInput = ({ props }) => {
    const inputLayout = { width: '50%', textAlign: 'center' }
    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        }
    };
    const { Option } = Select
    const { Text } = Typography
    const { confirm } = Modal

    let checkScoreInput = props.checkScoreInput
    let setCheckScoreInput = props.setCheckScoreInput
    let currentBattingTeam = props.currentBattingTeam
    let period = props.period
    let matchPeriods = props.matchPeriods
    let inningScoreInfo = props.inningScoreInfo
    let form = props.form
    let overConfirmationStatusDict = props.overConfirmationStatusDict
    let inningOverScoreDict = props.inningOverScoreDict
    let event = props.event
    let obumInningEnum = props.obumInningEnum
    let whoWinTossValue = props.whoWinTossValue
    let battingTeamToggleState = props.battingTeamToggleState
    let setBattingTeamToggleState = props.setBattingTeamToggleState
    let currentMatchPeriod = props.currentMatchPeriod
    let onEnableScoreInputChange = props.onEnableScoreInputChange
    let onWhoWinTossConfirm = props.onWhoWinTossConfirm
    let onBattingTeamConfirm = props.onBattingTeamConfirm
    let superOverToggleState = props.superOverToggleState
    let isResuming = props.isResuming
    let finalButtonType = props.finalButtonType
    let onFinalConfirm = props.onFinalConfirm
    let superOverTeamToggleState = props.superOverTeamToggleState
    let onSuperOverChange = props.onSuperOverChange
    let onSuperOverHAChange = props.onSuperOverHAChange
    let disableSOHomeAway = props.disableSOHomeAway
    let superOverVal = props.superOverVal
    let setDisableWhoWinToss = props.setDisableWhoWinToss
    let disableWhoWinToss = props.disableWhoWinToss
    let disableScoreInput = props.disableScoreInput
    let setDisableScoreInput = props.setDisableScoreInput
    let hasMatchStarted = props.hasMatchStarted
    let submitBallRunStatusChange = props.submitBallRunStatusChange
    let getWinTheTossTeam = props.getWinTheTossTeam
    
    const onScoreInputCheckboxChange = (e) => {
        let matchStarted = hasMatchStarted(event)
        if (matchStarted && !checkScoreInput) {
            Modal.info({
                title: 'Match started, score input will not be allowed.',
                onOk() { },
            });
            setDisableScoreInput(true)
        }
        else {
            submitScoreInputCheckboxChange(e.target.checked)
        }
    }

    const checkSuperOverConfirmation = (e) => {
        let over = 1
        const value = e.target.value

        if (matchPeriods.length > 4) {
            let lastMatchPeriod = matchPeriods.slice(-1)
            var confirmationStatusDict = overConfirmationStatusDict[lastMatchPeriod[0].matchPeriod]
            if (confirmationStatusDict !== undefined) {
                var lastStatus = confirmationStatusDict[Object.keys(confirmationStatusDict)[Object.keys(confirmationStatusDict).length - 1]]
                over = Object.keys(confirmationStatusDict)[Object.keys(confirmationStatusDict).length - 1]
                var hasLatestInningOverScoreExisted = Object.values(inningOverScoreDict[lastMatchPeriod[0].matchPeriod][over]).some(value => value !== '')

                if (lastStatus || (!hasLatestInningOverScoreExisted && over !== '1')) {
                    if (value === period.so1 || value === period.so2 || value === period.so3) {
                        submitSuperOverChange(value)
                    } else {
                        submitSuperOverTeamChange(value)
                    }
                } else {
                    showOverConfirmInfo(over)
                    if (value === period.so1 || value === period.so2 || value === period.so3) {
                        form.setFieldValue('superOver', value === period.so2 ? period.so1 : period.so2)
                    }
                }
            } else {
                showOverConfirmInfo(over)
                if (value === period.so1 || value === period.so2 || value === period.so3) {
                    form.setFieldValue('superOver', value === period.so2 ? period.so1 : period.so2)
                }
            }
        } else {
            if (value === period.so1 || value === period.so2 || value === period.so3) {
                submitSuperOverChange(value)
            } else {
                submitSuperOverTeamChange(value)
            }
        }
    }

    const constructWhoWinTossManualCricketResultInfo = () => {
        return {
            eventId: event.eventId,
            inningNo: obumInningEnum.coinTossResult,
            battingTeam: getWinTheTossTeam(),
            totalRuns: 1,
            totalWickets: null,
            completedOver: null,
            completedDelivery: null,
            inningDetailList: [],
            matchStartTime: event.matchStartTime,
            sessionId: AuthService.currentUserValue.sessionId,
            updatedBy: AuthService.currentUserValue.name,
            inning: currentMatchPeriod,
            requestId: "",
            uiRequestId: Date.now()
        }
    }

    const constructFinalManualCricketResultInfo = () => {
        return {
            eventId: event.eventId,
            inningNo: 0,
            battingTeam: '',
            totalRuns: null,
            totalWickets: null,
            completedOver: null,
            completedDelivery: null,
            inningDetailList: [],
            matchStartTime: event.matchStartTime,
            sessionId: AuthService.currentUserValue.sessionId,
            updatedBy: AuthService.currentUserValue.name,
            inning: currentMatchPeriod,
            requestId: "",
            uiRequestId: Date.now()
        }
    }

    const showDisableManualScoreInputConfirm = () => {
        confirm({
            title: 'Confirm to disable Manual Score Input?',
            content: 'Once disable, re-enable is not allow. Fancy bet will not offer once disable',
            cancelText: 'No',
            okText: 'Yes',
            onOk() {
                setCheckScoreInput(false)
                setDisableScoreInput(true)
            },
            onCancel() {
                setCheckScoreInput(true)
            },
        })
    }

    const showWhoWinTossConfirm = (teamName) => {
        confirm({
            title: <span>Confirm winner of coin toss is <Text mark>{teamName}</Text></span>,
            cancelText: 'No',
            okText: 'Yes',
            onOk() {
                submitWinTheTossTeamChange(teamName)
            },
            onCancel() {
                form.setFieldValue('winTheToss', whoWinTossValue)
            },
        });
    };

    const handleWhoWinTossButtonControls = async () => {
        try {
            if (matchPeriods.length >= 1 && Object.keys(inningOverScoreDict).length > 0) {
                var firstInn = matchPeriods[0].matchPeriod
                var noOfOvers = inningOverScoreDict[firstInn]['1']
                if (noOfOvers !== undefined) {
                    var noOfDelivery = Object.keys(noOfOvers).length
                    var deliveryVal = inningOverScoreDict[firstInn]['1']['0.1.0']
                    if (noOfDelivery >= 1 && deliveryVal !== undefined && deliveryVal !== '' && !disableWhoWinToss) {
                        setDisableWhoWinToss(true)
                        setBattingTeamToggleState([firstInn !== period.home, firstInn !== period.away, false, true])
                    
                        // Submit confirm who win toss to Obum
                        var resultInfo = constructWhoWinTossManualCricketResultInfo()
                        if(resultInfo !== null){
                            var resp = await ManualTradingService.updateManualCricketResultInfo(resultInfo)

                            if (resp) {
                                notification['success']({
                                    message: '',
                                    description: `Who win the toss ${whoWinTossValue} confirmed`,
                                })
                            }
                        }
                    }
                }
            }
        } catch (error) {
        }
    }

    const handleBattingTeamConfirm = (e) => {
        let selectedPeriod = e.target.value
        let over = 1

        form.setFieldValue('superOver', undefined)
        if (overConfirmationStatusDict !== null) {
            if (selectedPeriod === period.ht || selectedPeriod === period.ft) {
                var matchPeriodToQuery = selectedPeriod === period.ht ? matchPeriods[0].matchPeriod : matchPeriods[2].matchPeriod
                var confirmationStatusDict = overConfirmationStatusDict[matchPeriodToQuery]
                if (confirmationStatusDict !== undefined) {
                    var lastStatus = confirmationStatusDict[Object.keys(confirmationStatusDict)[Object.keys(confirmationStatusDict).length - 1]]
                    over = Object.keys(confirmationStatusDict)[Object.keys(confirmationStatusDict).length - 1]
                    var hasLatestInningOverScoreExisted = Object.values(inningOverScoreDict[matchPeriodToQuery][over]).some(value => value !== '')

                    if (lastStatus || (!hasLatestInningOverScoreExisted && over !== '1')) {
                        showBattingTeamConfirm(e)
                    } else {
                        showOverConfirmInfo(over)
                    }
                } else {
                    showOverConfirmInfo(over)
                }
            }
            else {
                showBattingTeamConfirm(e)
            }
        } else if (selectedPeriod === period.ht || selectedPeriod === period.ft) {
            showOverConfirmInfo(over)
        } else {
            showBattingTeamConfirm(e)
        }
    }

    const showOverConfirmInfo = (over) => {
        Modal.info({
            title: <span>Please confirm <Text mark>Over {over}</Text> before proceed.</span>,
            onOk() { form.setFieldValue('battingTeam', currentMatchPeriod) },
        });
    };

    const showBattingTeamConfirm = (e) => {
        const matchPeriod = e.target.value
        let title = ''
        let teamName = ''
        if (matchPeriod === period.home) {
            title = <span>Confirm <Text mark>{event.homeTeamName}</Text> is the batting team?</span>
            teamName = event.homeTeamName
        } else if (matchPeriod === period.away) {
            title = <span>Confirm <Text mark>{event.awayTeamName}</Text> is the batting team?</span>
            teamName = event.awayTeamName
        } else if (matchPeriod === period.ht) {
            title = <span> Current inning score is <Text mark>{inningScoreInfo[matchPeriods[0].matchPeriod]}</Text>. Are you sure to end the inning?</span>
            teamName = matchPeriod
        } else { // FT
            title = <span> Current inning score is <Text mark>{inningScoreInfo[matchPeriods[2].matchPeriod]}</Text>. Are you sure to end the inning?</span>
            teamName = matchPeriod
        }
        confirm({
            title: title,
            cancelText: 'No',
            okText: 'Yes',
            onOk() {
                submitBattingTeamChange(matchPeriod, teamName)
            },
            onCancel() {
                form.setFieldValue('battingTeam', currentMatchPeriod)
            },
        })
    }

    const checkFinalConfirmation = () => {
        let over = 1

        if (currentMatchPeriod === period.so1 || currentMatchPeriod === period.so2 || currentMatchPeriod === period.so3) {
            let lastMatchPeriod = matchPeriods.slice(-1)
            var confirmationStatusDict = overConfirmationStatusDict[lastMatchPeriod[0].matchPeriod]
            if (confirmationStatusDict !== undefined) {
                var lastStatus = confirmationStatusDict[Object.keys(confirmationStatusDict)[Object.keys(confirmationStatusDict).length - 1]]
                over = Object.keys(confirmationStatusDict)[Object.keys(confirmationStatusDict).length - 1]
                var hasLatestInningOverScoreExisted = Object.values(inningOverScoreDict[lastMatchPeriod[0].matchPeriod][over]).some(value => value !== '')

                if (lastStatus || (!hasLatestInningOverScoreExisted && over !== '1')) {
                    showFinalConfirm()
                } else {
                    showOverConfirmInfo(over)
                }
            } else {
                showOverConfirmInfo(over)
            }
        }
        else if (currentMatchPeriod === period.ft) {
            showFinalConfirm()
        }
    }

    const showFinalConfirm = () => {
        confirm({
            title: `Are you confirm to end the event?`,
            cancelText: 'No',
            okText: 'Yes',
            async onOk() {
                var resultInfo = constructFinalManualCricketResultInfo()
                var resp = await ManualTradingService.updateManualCricketResultInfo(resultInfo)

                if(resp) {
                    await submitEndMatch()
                }
                else{
                    notification['error']({
                        message: '',
                        description: `Failed to end match`,
                    })

                    var infoKeyValue = {
                        eventId: event?.eventId,
                        PropertyToUpdate: 'Final Result',
                        ValueToUpdate: JSON.stringify(resultInfo)
                    }
                    LoggingService.logError('updateManualCricketResultInfo', infoKeyValue, `Failed to update final result`)
                }
                
            },
            onCancel() {
            },
        })
    }

    function getCurrentInning(){
        if(currentMatchPeriod.startsWith('SO') && superOverVal !== null){
            return `${currentMatchPeriod} ${superOverVal}`
        }
        else{
            return currentMatchPeriod
        }
    }

    // async function submitMatchInfo(){
    //     if(event.eventId !== null && event.eventId !== undefined && !isResuming){
    //         var matchInfo = {
    //             eventId: event.eventId,
    //             homeTeamName: event.homeTeamName,
    //             homeTeamId: event.homeTeamId,
    //             awayTeamName: event.awayTeamName,
    //             awayTeamId: event.awayTeamId,
    //             matchStartTime: event.matchStartTime,
    //             competitionCategory: event.competitionCategory,
    //             competitionName: event.competition,
    //             winTheTossTeam: whoWinTossValue,
    //             currentInning: getCurrentInning(),
    //             isFinal: currentMatchPeriod === period.final,
    //             isManualScoreInputEnabled: checkScoreInput,
    //             sessionId: AuthService.currentUserValue.sessionId,
    //             updatedBy: AuthService.currentUserValue.name,
    //             matchPeriodList: matchPeriods
    //         }
    //         await ManualTradingService.updateManualCricketMatchInfo(matchInfo)
    //     }
    // }

    async function submitMatchInfoWithNotification(matchInfo, callerAction){
        if(event.eventId !== null && event.eventId !== undefined && !isResuming){
            var isSuccess = await ManualTradingService.updateManualCricketMatchInfo(matchInfo)
            if (!isSuccess){
                notification['error']({
                    message: '',
                    description: `Failed to ${callerAction}`,
                })
            }

            return isSuccess
        }

        return isSuccess
    }

    function constructMatchInfo(){
        return {
            eventId: event.eventId,
            homeTeamName: event.homeTeamName,
            homeTeamId: event.homeTeamId,
            awayTeamName: event.awayTeamName,
            awayTeamId: event.awayTeamId,
            matchStartTime: event.matchStartTime,
            competitionCategory: event.competitionCategory,
            competitionName: event.competition,
            winTheTossTeam: whoWinTossValue,
            currentInning: getCurrentInning(),
            isFinal: currentMatchPeriod === period.final,
            isManualScoreInputEnabled: checkScoreInput,
            sessionId: AuthService.currentUserValue.sessionId,
            updatedBy: AuthService.currentUserValue.name,
            matchPeriodList: matchPeriods,
            uiRequestId: Date.now()
        }
    }

    async function submitScoreInputCheckboxChange(isCheckboxChecked){
        var matchInfo = constructMatchInfo()
        matchInfo.isManualScoreInputEnabled = isCheckboxChecked

        var callerAction = `${isCheckboxChecked ? 'Enable' : 'Disable'} Score Input`
        var isSuccess = await submitMatchInfoWithNotification(matchInfo, callerAction)
        if(isSuccess){
            onEnableScoreInputChange(isCheckboxChecked)
        }
        else{
            var infoKeyValue = {
                eventId: matchInfo?.eventId,
                PropertyToUpdate: 'IsManualScoreInputEnabled',
                ValueToUpdate: isCheckboxChecked
            }
            LoggingService.logError('submitScoreInputCheckboxChange', infoKeyValue, `Failed to ${callerAction}`)
        }
    }

    async function submitWinTheTossTeamChange(winTheTossTeam){
        var matchInfo = constructMatchInfo()
        matchInfo.winTheTossTeam = winTheTossTeam

        var callerAction = `Update WinTheToss Team`
        var isSuccess = await submitMatchInfoWithNotification(matchInfo, callerAction)
        if(isSuccess){
            onWhoWinTossConfirm(winTheTossTeam)
        }
        else{
            var infoKeyValue = {
                eventId: matchInfo?.eventId,
                PropertyToUpdate: 'WinTheTossTeam',
                ValueToUpdate: winTheTossTeam
            }
            LoggingService.logError('submitWinTheTossTeamChange', infoKeyValue, `Failed to ${callerAction}`)
            form.setFieldValue('winTheToss', whoWinTossValue)
        }
    }

    async function submitBattingTeamChange(matchPeriod, teamName){
        var matchInfo = constructMatchInfo()
        matchInfo.currentInning = matchPeriod
        updateNewMatchPeriodToList(matchInfo, matchPeriod)

        var callerAction = `Update BattingTeam`
        var isSuccess = await submitMatchInfoWithNotification(matchInfo, callerAction)
        if (isSuccess) {
            onBattingTeamConfirm(matchPeriod, teamName)
            if ((matchPeriod !== period.ht && matchPeriod !== period.ft && matchPeriod !== period.final) ||
                (matchPeriods?.length === 1 && matchPeriod === period.ht)) {
                await submitBallRunStatusChange(matchPeriod, 0)
            }
        }
        else{
            var infoKeyValue = {
                eventId: matchInfo?.eventId,
                PropertyToUpdate: 'BattingTeam',
                ValueToUpdate: matchPeriod
            }
            LoggingService.logError('submitBattingTeamChange', infoKeyValue, `Failed to ${callerAction}`)
            form.setFieldValue('battingTeam', currentMatchPeriod)
        }
    }

    async function submitSuperOverChange(matchPeriod){
        var matchInfo = constructMatchInfo()
        matchInfo.currentInning = `${matchPeriod}`

        var callerAction = `Update SuperOver`
        var isSuccess = await submitMatchInfoWithNotification(matchInfo, callerAction)
        if(isSuccess){
            onSuperOverChange(matchPeriod)
        }
        else{
            var infoKeyValue = {
                eventId: matchInfo?.eventId,
                PropertyToUpdate: 'BattingTeam',
                ValueToUpdate: matchPeriod
            }
            LoggingService.logError('submitSuperOverChange', infoKeyValue, `Failed to ${callerAction}`)
            form.setFieldValue('superOver', currentMatchPeriod)
        }
    }

    async function submitSuperOverTeamChange(team){
        var matchInfo = constructMatchInfo()
        let soMatchPeriod = ""
        if (currentMatchPeriod.startsWith(period.so1)) {
            soMatchPeriod = team === period.soHome ? period.so1Home : period.so1Away
        }
        else if (currentMatchPeriod.startsWith(period.so2)) {
            soMatchPeriod = team === period.soHome ? period.so2Home : period.so2Away
        }
        else if (currentMatchPeriod.startsWith(period.so3)) {
            soMatchPeriod = team === period.soHome ? period.so3Home : period.so3Away
        }
        matchInfo.currentInning = `${soMatchPeriod}`
        updateNewMatchPeriodToList(matchInfo, soMatchPeriod)

        var callerAction = `Update SuperOver Team`
        var isSuccess = await submitMatchInfoWithNotification(matchInfo, callerAction)
        if(isSuccess){
            onSuperOverHAChange(team)
        }
        else{
            var infoKeyValue = {
                eventId: matchInfo?.eventId,
                PropertyToUpdate: 'BattingTeam',
                ValueToUpdate: soMatchPeriod
            }
            LoggingService.logError('submitSuperOverTeamChange', infoKeyValue, `Failed to ${callerAction}`)
            form.setFieldValue('superOverHA', superOverVal)
        }
    }

    async function submitEndMatch(){
        var matchInfo = constructMatchInfo()
        matchInfo.currentInning = period.final
        matchInfo.isFinal = true

        var callerAction = `Update EndMatch`
        var isSuccess = await submitMatchInfoWithNotification(matchInfo, callerAction)
        if(!isSuccess){
            var infoKeyValue = {
                eventId: matchInfo?.eventId,
                PropertyToUpdate: 'CurrentInning, IsFinal',
                ValueToUpdate: period.final
            }
            LoggingService.logError('submitEndMatch', infoKeyValue, `Failed to ${callerAction}`)
        }
        else{
            onFinalConfirm()

            notification['success']({
                message: '',
                description: `Final confirmed`,
            })
        }
    }

    function updateNewMatchPeriodToList(matchInfo, matchPeriod){
        let updatedMatchPeriods = matchPeriods
        if (matchPeriods?.length === 1 && matchPeriod !== period.ht) { // 1st Inn
            updatedMatchPeriods = updatedMatchPeriods.splice(0, 1)
        }
        if(!matchPeriods.some((item) => item.matchPeriod === matchPeriod)){
            updatedMatchPeriods = [...matchPeriods, { matchPeriod: matchPeriod }]
            matchInfo.matchPeriodList = updatedMatchPeriods
        }
    }

    useEffect(() => {
        handleWhoWinTossButtonControls()
    }, [inningOverScoreDict]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        
        <div>
            <Row justify="space-around" align="middle">
                <Col span={12}>
                    <Checkbox checked={checkScoreInput} onChange={(disableWhoWinToss || whoWinTossValue !== undefined) ? showDisableManualScoreInputConfirm : onScoreInputCheckboxChange} disabled={disableScoreInput}>Enable for Score Input</Checkbox>
                </Col>
            </Row>
            <br/>
            <Form form={form} name='matchInfo' disabled={!checkScoreInput}>
                <Row justify="space-around" align="middle">
                    <Col span={12}>
                        <h1 style={{ textAlign: 'center' }}>{event.homeTeamName} vs {event.awayTeamName}</h1>
                    </Col>
                </Row>
                <Row justify="space-around" align="middle">
                    <Col span={12}>
                        <h1 style={{ textAlign: 'center' }}>{currentMatchPeriod === period.home || currentMatchPeriod === period.away ? `1st Inning - ${currentBattingTeam}` : getCurrentInning() }</h1>
                    </Col>
                </Row>
                <Form.Item name="eventId" label="Event ID" {...formItemLayout}>
                    <Input style={{ width: '75%', color: 'black' }} disabled/>
                </Form.Item>
                <Form.Item name="matchStartTime" label="Match Start Time" {...formItemLayout}>
                    <Input style={{ width: '75%', color: 'black' }} disabled />
                </Form.Item>
                <Form.Item name="competitionCategory" label="Competition Category" {...formItemLayout}>
                    <Input style={{ width: '75%', color: 'black' }} disabled />
                </Form.Item>
                <Form.Item name="competition" label="Competition" {...formItemLayout}>
                    <Input style={{ width: '75%', color: 'black' }} disabled />
                </Form.Item>
                <Form.Item name="winTheToss" label="Win The Toss" {...formItemLayout}>
                    <Select placeholder="Please select who win the toss" onChange={showWhoWinTossConfirm} style={{ width: '75%' }} disabled={!checkScoreInput ? !checkScoreInput : disableWhoWinToss}>
                        {event.homeTeamName !== undefined && event.homeTeamName !== null && (
                            <Option value={event.homeTeamName}>{event.homeTeamName}</Option>
                        )}
                        {event.awayTeamName !== undefined && event.awayTeamName !== null && (
                            <Option value={event.awayTeamName}>{event.awayTeamName}</Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name="battingTeam" label="Batting Team" {...formItemLayout} >
                    <Radio.Group buttonStyle="solid" style={{ width: '75%' }} onChange={handleBattingTeamConfirm} >
                        <Row align="left">
                            <Radio.Button value={period.home} style={inputLayout} disabled={battingTeamToggleState[0]}>1st Inning Home</Radio.Button>
                            <Radio.Button value={period.away} style={inputLayout} disabled={battingTeamToggleState[1]}>1st Inning Away</Radio.Button>
                        </Row>
                        <Row align="left">
                            <Radio.Button value={period.ht} style={inputLayout} disabled={battingTeamToggleState[2]}>HT(Break)</Radio.Button>
                            <Radio.Button value={period.ft} style={inputLayout} disabled={battingTeamToggleState[3]}>FT</Radio.Button>
                        </Row>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Super Over" {...formItemLayout} >
                    <Form.Item name="superOver" {...formItemLayout} style={{ margin: 0 }} onChange={checkSuperOverConfirmation}>
                        <Radio.Group buttonStyle="solid" style={{ width: '100%' }} value={period.so1}>
                            <Radio.Button value={period.so1} style={{ width: '33%', textAlign: 'center' }} disabled={superOverToggleState[0]}>{period.so1}</Radio.Button>
                            <Radio.Button value={period.so2} style={{ width: '33%', textAlign: 'center' }} disabled={superOverToggleState[1]}>{period.so2}</Radio.Button>
                            <Radio.Button value={period.so3} style={{ width: '34%', textAlign: 'center' }} disabled={superOverToggleState[2]}>{period.so3}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="superOverHA" >
                        <Row align="left">
                            <Radio.Group buttonStyle="solid" style={{ width: '75%' }} onChange={checkSuperOverConfirmation} disabled={disableSOHomeAway} value={superOverVal}>
                                <Radio.Button style={inputLayout} value={period.soHome} disabled={superOverTeamToggleState[0]}>Home</Radio.Button>
                                <Radio.Button style={inputLayout} value={period.soAway} disabled={superOverTeamToggleState[1]}>Away</Radio.Button>
                            </Radio.Group>
                        </Row>
                    </Form.Item>
                </Form.Item>
                <Form.Item name="endMatch" label="End Match" {...formItemLayout}>
                    <Button name="finalBtn" type={finalButtonType} style={{ width: '37.5%', textAlign: 'center' }} onClick={checkFinalConfirmation} disabled={matchPeriods.length < 4 || matchPeriods.length === 5 || matchPeriods.length === 7 || matchPeriods.length === 9}>Final</Button>
                </Form.Item>
            </Form>
        </div>
    )
}
